/** @jsxImportSource @emotion/react */
import {
  BoxBody,
  Button,
  WhiteBox,
  Grid,
  RadioControl,
  RadioGroupF,
  FormikRedux,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  BoxHeader,
} from '@/components';
import { useTranslate } from '@/i18n/useTranslate';
import { Box, css } from '@mui/material';
import { useMQuery } from '@/hooks/useMQuery';
import * as yup from 'yup';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { OpenIdType } from '@/types';
import { useADConnect } from '@/store/companies/hooks/useADConnect';
import { InputControlF } from '@/components/FormikRedux/InputControlF';
import { EMPTY } from '@/constants';
import { Toggle } from 'ui-kit';
export interface ADForm {
  discoveryUrl: string;
  clientSecret: string;
  clientId: string;
  type: OpenIdType;
}
const initialValues: ADForm = {
  discoveryUrl: '',
  clientSecret: '',
  clientId: '',
  type: OpenIdType.Adfs,
};
const useValidationSchema = (validationEnabled: boolean) => {
  const { t } = useTranslate('companies');
  return useMemo(() => {
    const validation = !validationEnabled
      ? yup.string()
      : yup.string().required(t('FIELD_IS_REQUIRED'));
    return yup.object().shape({
      discoveryUrl: validation,
      clientSecret: validation,
      clientId: validation,
      type: validation,
    });
  }, [t, validationEnabled]);
};
export const ADSection = () => {
  const {
    resetErrors,
    submitForm,
    authorize,
    serverFormErrors,
    widgetState,
    companyOpenIdSettings,
    authorizeLater,
  } = useADConnect();
  const [adEnabled, setAdEnabled] = useState(false);
  const { t } = useTranslate('companies');
  const { mobile, xl } = useMQuery();
  const validationSchema = useValidationSchema(adEnabled);
  const submit = useCallback(
    (form: ADForm) => {
      submitForm(form, adEnabled);
    },
    [submitForm, adEnabled]
  );

  useEffect(() => {
    setAdEnabled(companyOpenIdSettings?.enable ?? false);
  }, [companyOpenIdSettings?.enable]);

  return (
    <>
      <WhiteBox sx={{ flex: 0 }}>
        <Box
          css={css`
            margin: 0 0 36px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <BoxHeader mb={0}>
            {t('CONNECTING_COMPANY_TO_THE_ACTIVE_DIRECTORY')}
          </BoxHeader>
          {!mobile && (
            <div>
              <Toggle
                labelLeft={adEnabled ? t('DISABLE') : t('ENABLE')}
                checked={adEnabled}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setAdEnabled(e.target.checked)
                }
              />
            </div>
          )}
        </Box>
        <BoxBody
          css={css`
            margin-bottom: 0;
            display: flex;
            flex-direction: column;
          `}
        >
          {mobile && (
            <Box sx={{ mb: 36 }}>
              <Toggle
                labelLeft={adEnabled ? t('DISABLE') : t('ENABLE')}
                checked={adEnabled}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setAdEnabled(e.target.checked)
                }
              />
            </Box>
          )}
          <FormikRedux<ADForm>
            id={'ad_form'}
            css={css`
              display: flex;
              flex-direction: column;
              height: 100%;
            `}
            initialValues={initialValues}
            onSubmit={submit}
            values={companyOpenIdSettings}
            serverErrors={serverFormErrors}
            resetServerErrors={resetErrors}
            validationSchema={validationSchema}
          >
            {(formik) => {
              return (
                <>
                  <Box
                    css={css`
                      flex: 1 1 auto;
                    `}
                    mb={36}
                  >
                    <RadioGroupF
                      name={'type'}
                      css={css`
                        .MuiFormGroup-root {
                          flex-direction: row;
                        }
                        flex-direction: row;
                        gap: 58px;
                      `}
                    >
                      <RadioControl value={OpenIdType.Adfs} label={t('ADFS')} />
                      <RadioControl
                        value={OpenIdType.Keycloak}
                        label={t('KEYCLOAK')}
                      />
                    </RadioGroupF>
                  </Box>
                  <Grid
                    container
                    columnSpacing={{ sm: '48px', lg: '36px' }}
                    rowSpacing={{ xs: 36, sm: 36 }}
                    sx={{ rowGap: { xs: 36, sm: 0 } }}
                  >
                    <Grid
                      xs={12}
                      lg={4}
                      css={css`
                        display: flex;
                        align-items: end;
                      `}
                    >
                      <InputControlF
                        compact={!xl}
                        name={'discoveryUrl'}
                        label={t('URL_UPPERCASED')}
                        placeholder={'http://example.com/url...'}
                      />
                    </Grid>
                    <Grid
                      xs={12}
                      sm
                      md
                      lg={2.5}
                      css={css`
                        display: flex;
                        align-items: end;
                        flex-grow: 1;
                      `}
                    >
                      <InputControlF
                        compact={!xl}
                        name={'clientId'}
                        label={t('CLIENT_ID')}
                        placeholder={EMPTY}
                      />
                    </Grid>
                    <Grid
                      xs={12}
                      sm
                      md
                      lg={2.5}
                      css={css`
                        display: flex;
                        align-items: end;
                      `}
                    >
                      <InputControlF
                        compact={!xl}
                        name={'clientSecret'}
                        label={t('CLIENT_SECRET')}
                        placeholder={'—'}
                      />
                    </Grid>
                    <Grid
                      xs={12}
                      sm={'auto'}
                      md={'auto'}
                      lg={3}
                      sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'end',
                        mt: { xs: 12, sm: 0 },
                      }}
                    >
                      <Button
                        disabled={
                          formik.dirty &&
                          !formik.isValid &&
                          formik.submitCount > 0
                        }
                        type={'submit'}
                        fullWidth={mobile}
                        color={'primary'}
                      >
                        {adEnabled ? t('CONTINUE') : t('SAVE')}
                      </Button>
                    </Grid>
                  </Grid>
                </>
              );
            }}
          </FormikRedux>
        </BoxBody>
      </WhiteBox>
      <Dialog
        open={widgetState === 'authorization'}
        size={'xs'}
        onClose={authorizeLater}
      >
        <DialogTitle onClose={authorizeLater}>{t('CONNECTING')}</DialogTitle>
        <DialogContent>{t('THE_DATA_IS_CORRECT')}</DialogContent>
        <DialogActions>
          <Button
            color={'secondary'}
            variant={'outlined'}
            onClick={authorizeLater}
          >
            {t('AUTHORIZE_LATER')}
          </Button>
          <Button color={'secondary'} onClick={() => authorize()}>
            {t('AUTHORIZE_NOW')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
