import { useTranslate } from '@/i18n/useTranslate';
import { Button, useBreakpoints } from 'ui-kit';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormikRedux,
} from '@/components';
import React from 'react';
import { useQrActions, useQrData } from '@/store/profile-qr/hooks';
import { TextAreaControlF } from '@/components/FormikRedux/TextAreaControlF';
import { useValidationSchema } from './useValidationSchema.tsx';

export const AddNewQrDialog = () => {
  const d = useQrData();
  const a = useQrActions();
  return (
    <Dialog
      open={d.step === 'create-new'}
      fullFrameMobile
      size={'sm'}
      onClose={a.cancelCreateNew}
    >
      <DialogLogic />
    </Dialog>
  );
};

const initValue = {
  description: '',
};

export const DialogLogic = () => {
  const { t } = useTranslate('profile');
  const b = useBreakpoints();
  const d = useQrData();
  const a = useQrActions();
  const schema = useValidationSchema();
  return (
    <FormikRedux
      initialValues={initValue}
      onSubmit={a.submitNew}
      serverErrors={d.errors}
      resetServerErrors={a.resetErrors}
      validationSchema={schema}
      noForm
    >
      {(f) => {
        return (
          <>
            <DialogTitle onClose={a.cancelCreateNew}>
              {t('MAKE_NEW_CONNECTION')}
            </DialogTitle>
            <DialogContent>
              <TextAreaControlF
                name={'description'}
                label={t('DESCRIPTION')}
                max={255}
              />
            </DialogContent>
            <DialogActions spaceBetween noBorder={b.sm}>
              <Button
                color={'secondary'}
                variant={'outlined'}
                disabled={f.isSubmitting}
                onClick={a.cancelCreateNew}
              >
                {t('CANCEL')}
              </Button>
              <Button
                color={'secondary'}
                disabled={f.isSubmitting}
                onClick={() => {
                  f.handleSubmit();
                }}
              >
                {t('SAVE')}
              </Button>
            </DialogActions>
          </>
        );
      }}
    </FormikRedux>
  );
};
