import React, {
  ChangeEvent,
  forwardRef,
  memo,
  useImperativeHandle,
  useState,
} from 'react';
import {
  SearchControl,
  Toggle,
  useBoxBreakpoints,
  useBreakpoint,
} from 'ui-kit';
import { Stack, Theme } from '@mui/material';
import { useTreeItems } from '@/features/module/LIMS/hooks/useTreeItems';
import { BoxHeader, boxXPaddings, boxYPaddings, Nothing } from '@/components';
import { LinkPath } from '@/features/module/LIMS/LinkPath';
import { useTranslate } from '@/i18n/useTranslate';
import { CONTENT_BOX_ID } from '@/features/layouts/MainLayout/MainLayout';

import { TreeList } from '@/features/module/LIMS/fragments/TreeList';
import { Skeleton } from '@/components/Skeleton';
import { SelectionDialogWarning } from '@/features/module/LIMS/fragments/SelectionDialogWarning.tsx';
import { UUID } from '@/types';
export interface TreeExternalHandle {
  expand: (id: UUID) => void;
}
export const TreeFragment = memo(
  forwardRef<TreeExternalHandle, { editable: boolean }>(({ editable }, ref) => {
    const {
      treeState: treeList,
      treePath,
      searchStr,
      select,
      expand,
      collapse,
      deselect,
      search,
      showNode,
      allSelected,
      initLoading,
    } = useTreeItems();
    useImperativeHandle(ref, () => {
      return {
        expand: showNode,
      };
    });
    const { t } = useTranslate('module');
    const containerBreakpoints = useBoxBreakpoints(CONTENT_BOX_ID, [
      'md',
      'xs',
    ]);
    const [selectAllToggleRequest, setSelectAllToggleRequest] = useState(false);

    const b = useBreakpoint('xs');

    const handleSelectAllToggle = () => {
      allSelected ? deselect() : select();
      setSelectAllToggleRequest(false);
    };

    return (
      <>
        <Stack sx={styles.leftTopSection}>
          <Stack direction={'row'} sx={styles.headerRow}>
            <BoxHeader mb={0}>{t('PERMISSIONS')}</BoxHeader>
            {editable && (
              <Toggle
                labelLeft={allSelected ? t('CLEAR_ALL') : t('ASSIGN_ALL')}
                checked={allSelected}
                onChange={() => {
                  setSelectAllToggleRequest(true);
                }}
              />
            )}
          </Stack>
          <SearchControl
            value={searchStr}
            delay={200}
            placeholder={t('SEARCH')}
            onChange={({ target: { value } }) => search(value)}
          />
        </Stack>
        {containerBreakpoints.xsOnly && treePath && !initLoading && (
          <Stack direction={'row'} sx={styles.expandPath}>
            <LinkPath
              links={treePath}
              size={b.xsOnly ? 'sm' : 'md'}
              onClick={expand}
            />
          </Stack>
        )}
        <Stack sx={styles.block}>
          {initLoading ? (
            <Stack sx={styles.containerPaddings}>
              <Skeleton variant={'even'} />
            </Stack>
          ) : (
            <Stack sx={styles.noSqueze} className={'first'}>
              {!treeList.length ? (
                <Stack sx={styles.containerPaddings}>
                  <Nothing>{t('NO_RESULTS_WERE_FOUND')}</Nothing>
                </Stack>
              ) : (
                <TreeList
                  list={treeList}
                  onExpand={(expanded, id) => {
                    if (!id) return;
                    expanded ? expand(id) : collapse(id);
                  }}
                  onChange={(e: ChangeEvent<HTMLInputElement>, id) => {
                    e.target.checked ? select(id) : deselect(id);
                  }}
                  match={searchStr}
                  editable={editable}
                />
              )}
            </Stack>
          )}
        </Stack>
        <SelectionDialogWarning
          open={selectAllToggleRequest}
          deselect={allSelected}
          onCancel={() => setSelectAllToggleRequest(false)}
          onSubmit={handleSelectAllToggle}
        />
      </>
    );
  })
);
TreeFragment.displayName = 'TreeFragment';

const styles = {
  block: { minHeight: 0, height: '100%', overflowX: 'hidden' },
  noSqueze: {
    width: '100%',
    height: '100%',
  },
  leftTopSection: {
    paddingX: boxXPaddings,
    paddingTop: boxYPaddings,
    paddingBottom: { xs: 18 },
    borderBottom: (t: Theme) => `1px solid ${t.palette.secondary[300] ?? ''}`,
  },
  headerRow: {
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: { xs: 18 },
  },

  expandPath: {
    gap: 6,
    paddingX: { xs: 18, sm: 24 },
    paddingY: 18,
    flexWrap: 'wrap',
  },
  containerPaddings: { paddingX: boxXPaddings, paddingY: { xs: 18, sm: 24 } },
};
