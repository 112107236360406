import { useTranslate } from '@/i18n/useTranslate';
import { FC, useMemo } from 'react';
import * as yup from 'yup';
import { FormikConfig } from 'formik';
import { PartialRecord } from '@/types';
import { BoxBody, BoxFooter, Button } from '@/components';
import { Stack, SxProps, Theme } from '@mui/material';
import { RoleFormType } from '@/features/module/ModulePage/fragments/CreateNewRoleForm';
import { InputControlF } from '@/components/FormikRedux/InputControlF';
import { INPUT_TIP_HEIGHT } from '@/components/common/Common';
import { FormikRedux } from '@/components/FormikRedux';
import { ValidationErrorType } from '@/types/ValidationError';
import { TextAreaControlF } from '@/components/FormikRedux/TextAreaControlF';
const NAME_MAX_SIZE = 100;
const DESCRIPTION_MAX_SIZE = 255;
const useValidationSchema = () => {
  const { t } = useTranslate('common');
  return useMemo(() => {
    return yup.object().shape({
      name: yup
        .string()
        .required(t('FIELD_IS_REQUIRED'))
        .max(NAME_MAX_SIZE, t('MAX_SIZE_', { count: NAME_MAX_SIZE })),
      description: yup
        .string()
        .required(t('FIELD_IS_REQUIRED'))
        .max(DESCRIPTION_MAX_SIZE, t('CHARACTER_LIMIT_EXCEEDED')),
    });
  }, [t]);
};
export const RoleFormView: FC<{
  edit?: boolean;
  onSubmit: FormikConfig<RoleFormType>['onSubmit'];
  resetErrors: () => void;
  cancel: () => void;
  values: RoleFormType | undefined;
  errors?: PartialRecord<keyof RoleFormType, ValidationErrorType> | null;
  className?: string;
  sx?: SxProps<Theme>;
}> = ({
  edit,
  onSubmit,
  resetErrors,
  cancel,
  errors,
  values,
  className,
  sx,
}) => {
  const schema = useValidationSchema();
  const { t } = useTranslate('companies');
  return (
    <FormikRedux<RoleFormType>
      noForm
      initialValues={{
        name: '',
        description: '',
      }}
      values={values}
      onSubmit={onSubmit}
      validateOnChange={true}
      validationSchema={schema}
      id={''}
      resetServerErrors={resetErrors}
      serverErrors={errors ?? {}}
    >
      {({ handleSubmit, isValid, dirty }) => {
        return (
          <Stack
            component={'form'}
            onSubmit={handleSubmit}
            className={className}
            mt={{ xs: 0 }}
            sx={sx}
          >
            <BoxBody
              sx={{
                mb: {
                  xs: 0,
                  sm: 12,
                  lg: 30,
                },
              }}
            >
              <InputControlF
                label={edit ? t('ROLE_NAME') : t('NEW_ROLE_NAME')}
                type={'text'}
                max={NAME_MAX_SIZE}
                sx={{
                  mb: {
                    xs: 60 - INPUT_TIP_HEIGHT,
                    sm: 36 - INPUT_TIP_HEIGHT,
                    lg: 60 - INPUT_TIP_HEIGHT,
                  },
                  maxWidth: { xs: 'initial', sm: 320, xl: 'initial' },
                }}
                name={'name'}
                tip={t('MAX_SIZE_IS_N_SYMBOLS_LONG', { n: NAME_MAX_SIZE })}
              />
              <TextAreaControlF
                errorMaxSymbols={t('CHARACTER_LIMIT_EXCEEDED')}
                placeholder={t('ADD_DESCRIPTION_PLEASE')}
                name={'description'}
                max={DESCRIPTION_MAX_SIZE}
              />
            </BoxBody>
            <BoxFooter>
              <Button
                color="primary"
                type="submit"
                disabled={!isValid || !dirty}
              >
                {t('SAVE')}
              </Button>
              <Button color={'primary'} variant="outlined" onClick={cancel}>
                {t('BACK')}
              </Button>
            </BoxFooter>
          </Stack>
        );
      }}
    </FormikRedux>
  );
};
