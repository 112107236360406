import React, { FC } from 'react';
import { Link } from 'ui-kit';
import LinkPathArrow from 'ui-kit/lib/icons/24/Arrow/ChevronRight';
import { UUID } from '@/types';
interface LinkPathProps {
  links: Array<{ label: string; disabled?: boolean; id: UUID }>;
  size?: Parameters<typeof Link>[0]['size'];
  onClick?: (uuid: UUID) => void;
}

export const LinkPath: FC<LinkPathProps> = ({ links, size, onClick }) => {
  return (
    <>
      {links.map((d) => {
        return (
          <Link
            size={size}
            color={'secondary'}
            key={d.label}
            sx={{ whiteSpace: 'nowrap' }}
            disabled={d.disabled}
            to={''}
            onClick={() => onClick?.(d.id)}
            iconRight={d.disabled ? undefined : <LinkPathArrow />}
          >
            {d.label}
          </Link>
        );
      })}
    </>
  );
};
