/** @jsxImportSource @emotion/react */
import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';
import { NumberPinCell } from './fragments/NumberPinCell';
import { PartialRecord } from '@/types';
import cn from 'classnames';
import { Stack, SxProps, Theme, styled, Box, css as _ } from '@mui/material';
import { Nowrap } from '../Text';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { shouldNotForwardProps } from '@/utils/shouldNotForwardProps';
import { PinInputContainer } from '@/components/PinCodeControl/fragments/styles';
import { spreadSx } from '@/utils/spreadSx';
import { SystemStyleObject } from '@mui/system';
import { CONTROL_CONTAINER } from '@/components/common/Common';
import { InputTip } from '@/components/common/InputTip';
import { Label } from '@/components/common/Label';

interface Range {
  start: number;
  end: number;
}
const inRange = (hostRange: Range, rangeToCheck: Range) => {
  const [hostStart, hostEnd] =
    hostRange.start > hostRange.end
      ? [hostRange.end, hostRange.start]
      : [hostRange.start, hostRange.end];
  const [targetStart, targetEnd] =
    rangeToCheck.start > rangeToCheck.end
      ? [rangeToCheck.end, rangeToCheck.start]
      : [rangeToCheck.start, rangeToCheck.end];

  if (hostStart === hostEnd || targetStart === targetEnd) {
    return false;
  }
  if (hostStart === targetStart || hostEnd === targetEnd) {
    return true;
  }

  if (hostStart < targetStart && hostEnd > targetStart) {
    return true;
  }

  if (hostStart < targetEnd && hostEnd > targetEnd) {
    return true;
  }

  return false;
};
const BodyWrapper = styled(
  Stack,
  shouldNotForwardProps(['error', 'success'])
)(() => ({
  flexDirection: 'row',
  height: '100%',
}));
interface PinCodeProps {
  compact?: boolean;
  length: number;
  label?: string;
  onChange?: (e: { target: { name?: string; value: string[] } }) => void;
  value?: string[] | null;
  className?: string;
  name?: string;
  error?: string;
  success?: string;
  tip?: string;
  fullWidth?: boolean | null | undefined;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  hideTipText?: boolean;
  slotBelowCells?: ReactNode;
}
export const PinCodeControl: FC<PinCodeProps> = ({
  length,
  label,
  value: externalValues,
  sx,
  disabled,
  onChange,
  name,
  className,
  error,
  fullWidth,
  hideTipText,
  success,
  tip,
  compact,
  slotBelowCells,
}) => {
  const id = useId();
  const inputRefs = useRef<Record<string, HTMLInputElement | null>>({});
  const [internalValues, setInternalValues] = useState<string[]>(
    [...Array(length)].fill('')
  );
  const [cellState, setCellState] = useState<
    PartialRecord<number, { selected?: boolean }>
  >({});

  const handlePinCodeChange = useCallback((index: number, value: string) => {
    setInternalValues((prevState) => {
      const state = [...prevState];
      state.splice(index, 1, value);
      return state;
    });
    if (value === '') {
      return;
    }
    const nextInput = inputRefs.current[index + 1];
    if (nextInput) {
      nextInput.focus();
    }
  }, []);

  const handlePaste = useCallback(
    (data: string, index: number) => {
      inputRefs.current?.[Math.min(data.length, length - 1)]?.focus();
      const newData = data.split('').slice(0, length);
      const newValue = internalValues
        .slice(0, index)
        .concat(newData)
        .concat(Array(length).fill(''))
        .slice(0, length);
      setInternalValues(newValue);
    },
    [internalValues, length]
  );

  const handleSelectNext = useCallback((index: number) => {
    inputRefs.current[index + 1]?.focus();
  }, []);

  const handleSelectPrev = useCallback(
    (index: number) => {
      if (Object.entries(cellState).some(([, d]) => d?.selected)) {
        return;
      }
      inputRefs.current[index - 1]?.focus();
    },
    [cellState]
  );

  const refs = useRef({ externalValues, internalValues, name, onChange });
  refs.current = { externalValues, internalValues, name, onChange };
  useEffect(() => {
    const tmt = window.setTimeout(() => {
      if (internalValues.join('') !== refs.current.externalValues?.join('')) {
        refs.current.onChange?.({
          target: { name: refs.current.name, value: internalValues },
        });
      }
    }, 200);
    return () => window.clearTimeout(tmt);
  }, [internalValues]);

  useEffect(() => {
    if (
      externalValues != null &&
      externalValues?.join('') !== refs.current.internalValues.join('')
    ) {
      setInternalValues(
        [...externalValues, ...Array(length).fill('')].slice(0, length) ??
          Array(length).fill('')
      );
    }
  }, [externalValues, length]);

  const cellStateRef = useRef(cellState);
  cellStateRef.current = cellState;
  const handleKeyUp: React.KeyboardEventHandler = (e) => {
    if (e.code === 'KeyA' && e.ctrlKey) {
      setCellState({ ...Array(length).fill({ selected: true }) });
    }
    if (e.key === 'Backspace') {
      if (Object.values(cellState).some((v) => v?.selected)) {
        setInternalValues((prevState) => {
          return [...prevState].map((d, i) => {
            if (cellState[i]?.selected) {
              return '';
            }
            return d;
          });
        });
        let selectionStart: number | undefined;
        setCellState((prev) => {
          const newState = Object.entries(prev)
            .sort(([a], [b]) => Number(a) - Number(b))
            .reduce<PartialRecord<string, { selected?: boolean }>>(
              (acc, [index, value]) => {
                if (selectionStart == null && value?.selected) {
                  selectionStart = Number(index);
                }
                acc[index] = { ...value, selected: false };
                return acc;
              },
              {}
            );
          inputRefs.current[selectionStart ?? 0]?.focus();
          return newState;
        });
      }
    }
  };
  const mouseDownHandler: React.MouseEventHandler = useCallback((e) => {
    const start = { x: e.pageX, y: e.pageY };
    let end = { ...start };
    setCellState({});
    const mouseMoveHandler = (e: MouseEvent) => {
      end = { x: e.pageX, y: e.pageY };
      Object.entries(inputRefs.current).forEach(([key, el]) => {
        const rect = el?.getBoundingClientRect();
        const hostRange = { start: start.x, end: end.x };
        const targetRange = {
          start: rect?.x ?? 0,
          end: (rect?.x ?? 0) + (rect?.width ?? 0),
        };
        if (inRange(hostRange, targetRange)) {
          setCellState((prev) => {
            return {
              ...prev,
              [key]: { selected: true },
            };
          });
        }
      });
    };
    const mouseUpHandler = (e: MouseEvent) => {
      end = { x: e.pageX, y: e.pageY };
      const [key] =
        Object.entries(cellStateRef.current)
          .sort(([a], [b]) => Number(a) - Number(b))
          .find(([, d]) => d?.selected) || [];
      inputRefs.current[Number(key)]?.focus();
      window.removeEventListener('mousemove', mouseMoveHandler);
      window.removeEventListener('mouseup', mouseUpHandler);
    };
    window.addEventListener('mousemove', mouseMoveHandler);
    window.addEventListener('mouseup', mouseUpHandler);
  }, []);

  const controlRef = useRef(null);
  useOnClickOutside(
    [controlRef],
    () => {
      setCellState({});
    },
    true
  );
  return (
    <ControlContainer
      className={cn(className, CONTROL_CONTAINER, 'container')}
      sx={[{ marginTop: 0 }, ...spreadSx(sx)]}
      disabled={disabled}
      onMouseDown={mouseDownHandler}
    >
      <BodyWrapper
        sx={[styles.wrapper, { flexDirection: compact ? 'column' : 'row' }]}
        ref={controlRef}
      >
        {label && (
          <Stack height={'100%'}>
            <Stack
              sx={[styles.labelContainer, { height: compact ? 'initial' : 42 }]}
            >
              <Label htmlFor={id}>
                <Nowrap>{label}</Nowrap>
              </Label>
            </Stack>
          </Stack>
        )}
        <Stack>
          <PinInputContainer onKeyUp={handleKeyUp} className={'pin-container'}>
            {internalValues.map((val, i) => {
              return (
                <NumberPinCell
                  id={i === 0 ? id : undefined}
                  key={i}
                  index={i}
                  ref={(ref) => {
                    inputRefs.current[i] = ref;
                  }}
                  onChange={handlePinCodeChange}
                  onPaste={handlePaste}
                  onSelectPrev={handleSelectPrev}
                  onSelectNext={handleSelectNext}
                  value={val}
                  fullWidth={fullWidth}
                  error={error}
                  success={!error && !!success}
                  selected={cellState[i]?.selected}
                />
              );
            })}
          </PinInputContainer>
          <InputTip
            hasHeight
            sx={{ minWidth: 316 }}
            hide={hideTipText}
            disabled={disabled}
            color={error ? 'error' : success ? 'success' : 'default'}
          >
            {error || success || tip}
          </InputTip>
          {slotBelowCells}
        </Stack>
      </BodyWrapper>
    </ControlContainer>
  );
};
PinCodeControl.defaultProps = {
  length: 6,
};

const styles: Record<string, SystemStyleObject<Theme>> = {
  iconContainer: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelContainer: {
    justifyContent: 'center',
  },
  wrapper: {
    border: 'none',
    width: 'fit-content',
    gap: 12,
  },
};

export const ControlContainer = styled(Box)<{ disabled?: boolean }>(
  ({ theme: t, disabled }) => _`
  margin-top: -18px;
  width: 100%;
  overflow: hidden;
  user-select: none;
  ${
    disabled
      ? `
    pointer-events: none;
    * {
      color: ${t.palette.grey[300]};
    }
    input::placeholder {
      color: ${t.palette.grey[300]}!important;
    }
  `
      : ''
  }
`
);
