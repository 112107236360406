import React, { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router';
import { NotFound } from '@/features/404';
import { ProtectedRoute } from '@/components/ProtectedRoute';
import { MainLayout } from '@/features/layouts/MainLayout';
import { SecurityPage } from '@/features/profile/Security';
import { useTranslate } from '@/i18n/useTranslate';
import ProfileForm from '@/features/profile/ProfileEdit';
import { MainProfilePage } from '@/features/profile/MainProfilePage';
import { CompanyDetailsPage } from '@/features/company-details/CompanyDetailsPage';
import { CompanyUsersControlPage } from '@/features/users-control';
import { ModulePage } from '@/features/module/ModulePage/ModulePage';
import { PermissionsPage } from '@/features/module/permissions/PermissionsPage';
import { UsersList } from '@/features/module/users-list';
import { EditablePermissionsPage } from '@/features/module/permissions/EditablePermissionsPage';
import { AppRoutes } from './appRoutes';
import RecoveryPage from '@/features/authentication/Recovery';
import ProfileInfo from '@/features/profile/ProfileInfo';
import { LimsPermissionsPage } from '@/features/module/LIMS';
import { isAuthorized } from '@/components/ProtectedRoute/utils.ts';

const Authentication = lazy(() => import('@/features/authentication'));
const RegistrationPage = lazy(
  () => import('@/features/authentication/Registration')
);
const LoginPage = lazy(() => import('@/features/authentication/Login'));
const ActivationPage = lazy(
  () => import('@/features/authentication/Activation')
);
const PhoneEmailsPage = lazy(
  () => import('@/features/profile/phones-and-emails/PhoneEmailsPage')
);
const ProfilePagesWrapper = lazy(
  () => import('@/features/profile/ProfilePagesWrapper')
);
const Fallback = () => {
  const { t, ready } = useTranslate('common');
  return <>{ready ? t('LOADING') : '...'}</>;
};

export const AppRouter: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute name="authenticated" redirectPath={AppRoutes.AUTH} />
        }
      >
        <Route path="/" element={<MainLayout />}>
          <Route
            index
            element={
              <Navigate
                to={{
                  pathname: AppRoutes.PROFILE,
                  search: window.location.search,
                }}
              />
            }
          />
          <Route
            path={AppRoutes.PROFILE}
            element={
              <Suspense fallback={<Fallback />}>
                <ProfilePagesWrapper />
              </Suspense>
            }
          >
            <Route path={''} element={<MainProfilePage />}>
              <Route index element={<ProfileInfo />} />
              <Route path={'edit'} element={<ProfileForm />} />
            </Route>
            <Route
              path={AppRoutes.PROFILE_PHONE_EMAILS}
              element={
                <Suspense fallback={<Fallback />}>
                  <PhoneEmailsPage />
                </Suspense>
              }
            />
            <Route
              path={AppRoutes.PROFILE_SECURITY}
              element={<SecurityPage />}
            />
            <Route
              path={'*'}
              element={
                <Navigate
                  to={{
                    pathname: AppRoutes.PROFILE,
                    search: window.location.search,
                  }}
                />
              }
            />
          </Route>
          <Route
            path={AppRoutes.COMPANY_DETAILS}
            element={<CompanyDetailsPage />}
          />
          <Route
            path={AppRoutes.COMPANY_USERS_CONTROL}
            element={<CompanyUsersControlPage />}
          />
          <Route path={AppRoutes.COMPANY_MODULES} element={<ModulePage />} />
          <Route
            path={AppRoutes.COMPANY_MODULES_USERS}
            element={<UsersList />}
          />
          <Route
            path={AppRoutes.COMPANY_MODULES_PERMISSIONS}
            element={<PermissionsPage />}
          />
          <Route
            path={AppRoutes.COMPANY_MODULES_PERMISSIONS_LIMS}
            element={<LimsPermissionsPage />}
          />
          <Route
            path={AppRoutes.COMPANY_MODULES_PERMISSIONS_LIMS_EDIT}
            element={<LimsPermissionsPage editable={true} />}
          />
          <Route
            path={AppRoutes.COMPANY_MODULES_PERMISSIONS_EDIT}
            element={<EditablePermissionsPage />}
          />
        </Route>
      </Route>
      <Route
        element={
          <ProtectedRoute
            name="auth"
            unauthorizedOnly
            redirectPath={AppRoutes.PROFILE}
          />
        }
      >
        <Route
          path={'/auth'}
          element={
            <Suspense fallback={<Fallback />}>
              <Authentication />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Navigate
                to={{
                  pathname: AppRoutes.AUTH,
                  search: window.location.search,
                }}
              />
            }
          />
          <Route
            path={AppRoutes.AUTH}
            element={
              <Suspense fallback={<Fallback />}>
                <LoginPage />
              </Suspense>
            }
          />
          <Route
            path={AppRoutes.REGISTRATION}
            element={
              <Suspense fallback={<Fallback />}>
                <RegistrationPage />
              </Suspense>
            }
          />
          <Route
            path={AppRoutes.RECOVERY}
            element={
              <Suspense fallback={<Fallback />}>
                <RecoveryPage />
              </Suspense>
            }
          />
          <Route
            path={AppRoutes.ACTIVATION}
            element={
              <Suspense fallback={<Fallback />}>
                <ActivationPage />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <Navigate
                to={{
                  pathname: AppRoutes.AUTH,
                  search: window.location.search,
                }}
              />
            }
          />
        </Route>
      </Route>
      <Route path={'/s'}>
        <Route path={'invite'} element={<ShortLinksHandler />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const ShortLinksHandler = () => {
  const isAuthenticated = isAuthorized();
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated) {
      navigate({ pathname: '/', search: window.location.search });
    } else {
      navigate({
        pathname: AppRoutes.ACTIVATION,
        search: window.location.search,
      });
    }
  }, [isAuthenticated, navigate]);
  return <></>;
};
