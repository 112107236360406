import { Field, FieldProps } from 'formik';
import { getErrorForDisplay } from '@/components/FormikRedux/useFormikError';
import { SelectControl, SelectControlProps } from '@/components/SelectControl';
import { RequiredAny } from 'ui-kit/lib/types';

type Props<T> = Omit<SelectControlProps<T>, 'name' | 'value'> & {
  name: string;
};

export const SelectControlF = <T = string,>({
  children,
  ...props
}: Props<T>) => {
  return (
    <Field {...props}>
      {({ field, form, meta }: FieldProps) => {
        const e = getErrorForDisplay({
          error: meta.error,
          value: meta.value,
          touched: meta.touched,
          submitCount: form.submitCount,
        });
        return (
          <SelectControl<T>
            {...(props as RequiredAny)}
            {...field}
            value={field.value ?? ''}
            error={e}
          >
            {children}
          </SelectControl>
        );
      }}
    </Field>
  );
};
