import { Button } from '@/components/Button';
import { useTranslate } from '@/i18n/useTranslate';
import { PropsWithChildren } from 'react';
import {
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'ui-kit';
import { Box, styled, useTheme } from '@mui/material';
import { useMQuery } from '@/hooks/useMQuery';
import { Header } from '@/components/Dialog/Header.tsx';

export interface DialogAlertProps {
  open: boolean;
  title: string;
  type?: 'warning' | 'success' | 'info' | 'error';
  onClose: () => void;
  className?: string;
  buttons?: (Omit<ButtonProps, 'variant'> & {
    variant: 'primary' | 'secondary';
  })[];
}

const AlertDialogActions = styled(DialogActions)({
  margin: '0 !important',
  padding: '24px',
});
export const Warning = ({
  open,
  title,
  children,
  type = 'warning',
  onClose,
  className,
  buttons,
}: PropsWithChildren<DialogAlertProps>) => {
  const { t } = useTranslate('common');
  const { tablet, mobile } = useMQuery();
  const theme = useTheme();
  let color: string;
  switch (type) {
    case 'success':
      color = theme.palette.success.main;
      break;
    case 'error':
      color = theme.palette.error.main;
      break;
    case 'warning':
      color = theme.palette.warning.main;
      break;
    case 'info':
      color = theme.palette.info.main;
      break;
    default:
      color = '';
  }
  return (
    <Dialog
      size={tablet || mobile ? 'xs' : 'sm'}
      open={open}
      onClose={onClose}
      className={className}
      keepMounted={false}
    >
      <DialogTitle
        onClose={onClose}
        sx={{ border: `1px solid ${color}`, h2: { display: 'flex' } }}
      >
        <Header type={type}>{title}</Header>
      </DialogTitle>
      <DialogContent
        sx={{
          fontSize: '14px',
          textAlign: 'left !important',
          lineHeight: '20px',
          fontWeight: 500,
          border: `1px solid ${color}`,
          borderTop: 'none',
          p: '36px 24px!important',
        }}
      >
        {children}
      </DialogContent>
      <AlertDialogActions
        sx={{
          background: color,
          borderTop: 'none !important',
          pt: 16,
          pb: 16,
          justifyContent: { xs: 'center', sm: 'space-between !important' },
        }}
      >
        {buttons ? (
          buttons.map(({ variant, ...props }, i) => (
            <Button
              key={i}
              {...props}
              variant={variant === 'primary' ? 'contained' : 'outlined'}
              color={'white'}
              sx={styles[variant]}
            />
          ))
        ) : (
          <>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }} />
            <Button variant={'contained'} color={'white'} onClick={onClose}>
              {t('OK')}
            </Button>
          </>
        )}
      </AlertDialogActions>
    </Dialog>
  );
};

const styles = {
  primary: {},
  secondary: {},
};
