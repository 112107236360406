import { useTranslate } from '@/i18n/useTranslate';
import { FC, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import { Tooltip } from 'ui-kit';
import { Stack, Typography } from '@mui/material';
import { CheckboxControlF } from '@/components/FormikRedux/CheckboxControlF';
import AlertIcon from 'ui-kit/lib/icons/24/Warning/Info';
import { useCompanyCarriers } from '@/store/insurance/hooks';
import { CompanyAddress } from '@/api/__generated__/webApi';
import { Label } from '@/components/common/Label';

export const BillTypesControls: FC<{ error?: string }> = ({ error }) => {
  const { t } = useTranslate('companies');
  const iconRef = useRef<HTMLDivElement>(null);
  const ctx = useFormikContext<CompanyAddress>();
  const { hasInsuranceCarriers, updateCarriersList } = useCompanyCarriers();
  const insuranceDisabled = !hasInsuranceCarriers;

  useEffect(() => {
    updateCarriersList();
    // ignore: updateCarriersList
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { insuranceBillType, clientBillType, patientBillType } =
    ctx.initialValues;
  const { setFieldValue } = ctx;
  useEffect(() => {
    if (insuranceBillType || clientBillType || patientBillType) {
      void setFieldValue('hasInitialBillType', true);
    }
  }, [clientBillType, insuranceBillType, patientBillType, setFieldValue]);

  const insurance = insuranceDisabled ? (
    <Tooltip
      PopperProps={{
        anchorEl: () => {
          return iconRef.current as HTMLElement;
        },
      }}
      title={t('THERE_IS_NO_INSURANCE_COMPANY_')}
      placement={'bottom'}
      sx={{ maxWidth: 200 }}
    >
      <Stack direction={'row'} gap={12} sx={{ alignItems: 'center' }}>
        <CheckboxControlF
          name={'insuranceBillType'}
          disabled
          label={t('INSURANCE')}
          submitCount={ctx.submitCount}
          error={!!error}
        />
        <Stack
          ref={iconRef}
          sx={{ justifyContent: 'center', color: (t) => t.palette.grey[600] }}
        >
          <AlertIcon />
        </Stack>
      </Stack>
    </Tooltip>
  ) : (
    <CheckboxControlF
      name="insuranceBillType"
      label={t('INSURANCE')}
      submitCount={ctx.submitCount}
    />
  );

  return (
    <Stack gap={{ xs: 12, md: 12, lg: 12 }}>
      <Stack gap={{ xs: 24 }}>
        <Label>{t('BILL_TYPES_AVAILABILITY')}</Label>
        <Stack
          gap={{
            xs: 24,
            sm: 36,
          }}
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          sx={{ display: 'inline-flex' }}
        >
          {insurance}
          <CheckboxControlF
            name={'patientBillType'}
            label={t('PATIENT')}
            submitCount={ctx.submitCount}
            error={!!error}
          />
          <CheckboxControlF
            name={'clientBillType'}
            label={t('CLIENT')}
            submitCount={ctx.submitCount}
            error={!!error}
          />
        </Stack>
      </Stack>
      {error && (
        <Typography variant={'12_16_500'} color={(t) => t.palette.error.main}>
          {error}
        </Typography>
      )}
    </Stack>
  );
};
