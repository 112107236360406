/** @jsxImportSource @emotion/react */
import { Box, css, Stack, Typography } from '@mui/material';
import { Button } from '@/components';
import { Avatar } from '@/components/Avatar';
import { useTranslate } from '@/i18n/useTranslate';
import React, { FC } from 'react';
import { useCompanyData } from '@/store/companies/hooks';
// todo [tech-debt] currently not presented in the ui-kit
import PuzzleSVGIcon from '@/images/icons/icon_puzzle.svg?react';
import { ParamNames, useParams } from '@/routes/appRoutes';
import { useADAuthorize } from '@/store/companies/hooks/useADConnect';
import { useCompanyLogo } from '@/store/companies/hooks/useCompanyLogo';
import { CompanyPermissionName, UUID } from '@/types';
import { AddModuleButton } from '@/features/module/AddModuleButton';
import { useCompanyPermissionsValidator } from '@/store/permissions/useCompanyPermissionsValidator.tsx';
import { useBreakpoint } from 'ui-kit';

export const CompanyHeader: FC = () => {
  const { name, company } = useCompanyData();
  const { t } = useTranslate('companies');
  const { md } = useBreakpoint('md');
  const params = useParams();
  const authorize = useADAuthorize({ companyId: params[ParamNames.CompanyId] });
  const showAuthorizeButton = !!company && company?.locked;
  return (
    <>
      <div
        css={css`
          display: flex;
          width: 100%;
          gap: 24px;
        `}
      >
        <div
          css={[
            css`
              display: flex;
              gap: 24px;
              flex: 1 1 auto;
            `,
          ]}
        >
          {md && (
            <Box>
              <CompanyAvatar
                companyId={params[ParamNames.CompanyId]}
                name={name ?? ''}
              />
            </Box>
          )}
          <div>
            <Typography
              sx={{
                typography: { xs: '24_32_700', sm: '32_36_700' },
                pb: { xs: 6, sm: 12 },
              }}
              component={'h2'}
            >
              {name}
            </Typography>
            {company?.npi && (
              <Stack direction={'row'}>
                <Typography
                  sx={{ typography: { xs: '14_18_500', sm: '18_24_500' } }}
                >
                  {t('NPI_N', { npi: company?.npi })}
                </Typography>
              </Stack>
            )}
          </div>
        </div>
        <div>
          {md && (
            <Stack direction={'row'} gap={12}>
              <AddModuleButton
                variant={showAuthorizeButton ? 'outlined' : 'contained'}
              />
              {showAuthorizeButton && (
                <Button color={'secondary'} onClick={() => authorize()}>
                  {t('AUTHORIZE')}
                </Button>
              )}
            </Stack>
          )}
        </div>
      </div>
    </>
  );
};
interface PAProps {
  companyId: UUID | undefined;
  name: string | undefined;
  className?: string;
}
export const CompanyAvatar: FC<PAProps> = ({ companyId, name }) => {
  const { company } = useCompanyData(companyId);
  const has = useCompanyPermissionsValidator(true);
  const hasRights = has(CompanyPermissionName.CompanyEditLogo);
  const { openDialog } = useCompanyLogo();

  return (
    <Avatar
      size={72}
      text={name ?? ''}
      src={company?.logoUrl}
      uploadIcon={hasRights}
      onClick={
        hasRights
          ? () => {
              openDialog(companyId as UUID);
            }
          : undefined
      }
      sx={{ cursor: hasRights ? 'pointer' : undefined }}
      square
      emptyIcon={<PuzzleSVGIcon />}
    />
  );
};
