import { NormalizedState } from '@/store/common/normalized.ts';
import { Role } from '@/api/__generated__/webApi';
import { Stack } from '@mui/material';
import { RoleItem } from '@/features/module/ModulePage/fragments/RoleItem.tsx';
import { CustomRolesList } from '@/features/module/ModulePage/CustomRolesList.tsx';
import React from 'react';

interface RoleListProps {
  roles: NormalizedState<Role, string>;
  customRoleIds: string[] | null | undefined;
  disableEdit: boolean;
}

export const RolesLists = ({
  roles,
  customRoleIds,
  disableEdit,
}: RoleListProps) => {
  return (
    <>
      <Stack sx={[styles.roles]}>
        {roles.allIDs
          .filter((id) => !roles.byID[id]?.editable)
          .map((id) => {
            return <RoleItem key={id} role={roles.byID[id]} />;
          })}
      </Stack>
      <CustomRolesList
        roles={roles}
        customRolesIds={customRoleIds}
        disableEdit={disableEdit}
      />
    </>
  );
};
const styles = {
  roles: { gap: { xs: 48, lg: 24 } },
};
