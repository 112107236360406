/** @jsxImportSource @emotion/react */
import {
  Box,
  Button,
  css as _,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import { FC, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useTranslate } from '@/i18n/useTranslate';
import { useDataUrl } from '@/hooks/useDataUrl';
import { FileChangeHandler } from '@/components/FileInput';
import { PhotoUpload } from '@/components/FileInput/PhotoUpload';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@/components';
import IconSVGLongArrow from 'ui-kit/lib/icons/24/Arrow/ArrowLeft';
import { PhotoEditor } from '@/components/PhotoEditor';
import { useCompanyLogo } from '@/store/companies/hooks/useCompanyLogo';
import IconSVGTimes from 'ui-kit/lib/icons/24/Action/Close';
import { useCompanyData } from '@/store/companies/hooks';

const editDialogStyle = (t: Theme) =>
  _`.MuiDialog-paper {background: ${t.palette.grey[600]};}`;

interface EditAvatarProps {
  open: boolean;
  onClose?: () => void;
}

export const UploadCompanyAvatarDialog: FC<EditAvatarProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslate('profile');
  const { companyId, upload } = useCompanyLogo();
  const { company } = useCompanyData(companyId);
  const resetRef = useRef(() => {});
  const [file, setFile] = useState<File | undefined>();
  const [step, setStep] = useState<'upload' | 'edit'>('upload');
  const fileUrl = useDataUrl(file);
  const validUrl = fileUrl || company?.logoUrl;
  const onChange: FileChangeHandler = useCallback((e, files) => {
    if (files.length) {
      setFile(files[0]);
      setStep('edit');
    }
  }, []);

  const saveEditedFile = (d: File) => {
    setFile(d);
    setStep('upload');
  };

  const submitFile = () => {
    if (file) {
      upload(file);
      onClose?.();
    }
  };

  useLayoutEffect(() => {
    if (open) {
      setFile(undefined);
      setStep('upload');
    }
  }, [open]);

  const editStep = step === 'edit';

  const uploadStepContent = (
    <>
      <PhotoUpload src={validUrl} onChange={onChange} round={false} />
      <Box mt={36}>
        <Typography variant={'14_20_400'} textAlign={'left'}>
          {t('YOUR_PROFILE_PHOTO_WILL_HELP_OTHER_PEOPLE_RECOGNIZE_YOU_')}
        </Typography>
      </Box>
    </>
  );

  const editStepContent = (
    <PhotoEditor
      resetRef={resetRef}
      formId={'crop_form'}
      round={false}
      file={file}
      onSubmit={saveEditedFile}
    />
  );

  return (
    <Dialog open={open} onClose={onClose} css={editStep && editDialogStyle}>
      {step === 'upload' ? (
        <DialogTitle onClose={onClose}>{t('UPLOAD_PHOTO')}</DialogTitle>
      ) : (
        <DialogTitle
          noContent
          onClose={() => {}}
          css={_`color: white; display: flex; justify-content: space-between;`}
          sx={{ paddingX: { xs: 24 } }}
        >
          <Box sx={{ display: 'flex', gap: 16 }}>
            <IconButton
              onClick={() => {
                setStep('upload');
                setFile(undefined);
              }}
            >
              <Box
                component={IconSVGLongArrow}
                size={20}
                sx={{ color: 'common.white' }}
              />
            </IconButton>
            <ResetButton onClick={() => resetRef.current()} />
          </Box>
          <span>{t('CROP_AND_ROTATE')}</span>
        </DialogTitle>
      )}
      <DialogContent css={editStep && _`color: white`}>
        {step === 'upload' && uploadStepContent}
        {step === 'edit' && editStepContent}
      </DialogContent>
      <DialogActions spaceBetween>
        <Button
          variant={'outlined'}
          color={editStep ? 'white' : 'secondary'}
          onClick={onClose}
        >
          {t('CANCEL')}
        </Button>
        {step === 'edit' && (
          <Button
            variant={'contained'}
            color={'white'}
            type={'submit'}
            form={'crop_form'}
          >
            {t('APPLY')}
          </Button>
        )}
        {step === 'upload' && (
          <Button
            variant={'contained'}
            color={'secondary'}
            disabled={!file}
            onClick={submitFile}
          >
            {t('APPLY')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export const ResetButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslate('common');
  return (
    <Button
      variant={'outlined'}
      color={'white'}
      size={'small'}
      sx={{ minWidth: 'auto', borderRadius: '20px' }}
      onClick={onClick}
      startIcon={
        <Box
          component={IconSVGTimes}
          size={14}
          sx={{ color: 'common.white' }}
        />
      }
    >
      {t('RESET')}
    </Button>
  );
};
