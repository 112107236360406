/** @jsxImportSource @emotion/react */
import { FC, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { MAIN_MENU_PADDING, MenuList } from './Menu/styles';
import classNames from 'classnames';
import { Collapse, css as _, Stack } from '@mui/material';
import { SIDE_MENU_WIDTH } from '../layouts/MainLayout/styles';
import { MenuItem } from './Menu/MenuItem';
import { GoToModuleButton } from './Menu/GoToModuleButton';
import { SubMenuItem } from './Menu/SubMenuItem';
import { SideMenuItem } from './SidebarContainer';
import { useParams } from '@/routes/appRoutes';

export const SidebarMenu: FC<{
  menuItems: SideMenuItem[];
  className?: string;
}> = ({ menuItems, className }) => {
  const [collapseState, setCollapseState] = useState<Record<string, boolean>>(
    {}
  );
  const [opacity, setOpacity] = useState(0);
  const { companyId } = useParams();
  const handleCollapse = useCallback(
    (index: number) => (collapse?: boolean) => {
      setCollapseState((st) => {
        return {
          [index]: typeof collapse !== 'undefined' ? !collapse : !st[index],
        };
      });
    },
    []
  );

  useLayoutEffect(() => {
    if (companyId) {
      const index = menuItems.findIndex(({ id }) => id === companyId);
      if (index) {
        setCollapseState(() => {
          return {
            [index]: true,
          };
        });
      }
    }
  }, [companyId, menuItems]);

  useEffect(() => {
    setOpacity(1);
  }, []);

  return (
    <MenuList
      className={classNames(className, 'MenuList')}
      css={_`
        width: ${SIDE_MENU_WIDTH}px;
        opacity: ${opacity};
        transition: all .3s; transition-delay: .2s`}
    >
      {menuItems.map((m, i) => {
        return (
          <div key={i}>
            <MenuItem
              title={m.title}
              subtitle={m.subtitle}
              exact={m.link?.exact}
              icon={m.icon}
              selected={m.selected}
              disabled={m.disabled}
              header={true}
              onCollapse={
                m.collapsible ? () => handleCollapse(i)(false) : undefined
              }
              expanded={collapseState[i]}
              to={{
                pathname: m.link?.pathname,
                search: m.link?.search ?? window.location.search,
              }}
            />
            <div>
              {m.buttons?.map((b, i) => {
                return (
                  <Stack
                    direction={'row'}
                    sx={{
                      height: 50,
                      alignItems: 'center',
                      paddingLeft: MAIN_MENU_PADDING,
                    }}
                    key={i}
                  >
                    <GoToModuleButton
                      href={(b.link?.pathname ?? '') + (b.link?.search ?? '')}
                      data={b.data}
                    >
                      <span>{b.title}</span>
                    </GoToModuleButton>
                  </Stack>
                );
              })}
            </div>
            <Collapse
              in={m.collapsible ? collapseState[i] : true}
              timeout={'auto'}
              unmountOnExit
            >
              {m.items?.map((m, i) => {
                return (
                  <SubMenuItem
                    key={i}
                    title={m.title}
                    subtitle={m.subtitle}
                    exact={m.link?.exact}
                    icon={m.icon}
                    showIcon={!m.iconStripeOnly}
                    selected={m.selected}
                    disabled={m.disabled}
                    to={{
                      pathname: m.link?.pathname,
                      search: m.link?.search ?? window.location.search,
                    }}
                  />
                );
              })}
            </Collapse>
          </div>
        );
      })}
    </MenuList>
  );
};
