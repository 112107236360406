import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormikRedux,
  Grid,
} from '@/components';
import { phoneNotRequiredValidator } from '@/features/authentication/common/validators';
import { useTranslate } from '@/i18n/useTranslate';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useEditAddressForm } from '@/store/companies/hooks/useEditAddressForm';
import { ISODateString } from '@/types';
import * as yup from 'yup';
import IconWarning from 'ui-kit/lib/icons/24/Warning/Attention';
import { Box, Stack, Typography } from '@mui/material';
import { SwitchControlF } from '@/components/SwitchControl';
import IconSVGInformationMark from 'ui-kit/lib/icons/24/Warning/Info';
import { CompanyAddress } from '@/api/__generated__/webApi';
import { AddressLookup } from '@/components/Google/AddressLookup';
import { BillTypesControls } from '@/features/company-addresses/BillTypesControls';
import { AnySchema } from 'yup';
import { getErrorForDisplay } from '@/components/FormikRedux/useFormikError';
import { PhoneInputControlF } from '@/components/FormikRedux/PhoneInputControlF';
import { InputControlF } from '@/components/FormikRedux/InputControlF';
import { GrayLayout } from '@/features/company-addresses/AddAddressDialog';
import { useBreakpoints } from 'ui-kit';
import { Dl } from '@/components/Dl';
import { DateControlF } from '@/components/FormikRedux/DateControlF.tsx';
import { DateTime } from 'luxon';
// Why ANY - TODO [tech debt] Need to update YUP to apply better approach
// eslint-disable-next-line
export type YupShape<Fields extends Record<string, any>> = Partial<
  Record<keyof Fields, AnySchema>
>;

export interface EditAddressForm {
  address2: string;
  telephoneNumber: string;
  faxNumber: string;
  endDate: ISODateString;
  invoicesReceiving: boolean;
  insuranceBillType: boolean;
  patientBillType: boolean;
  clientBillType: boolean;
  hasInitialBillType?: boolean;
}
const initialValues: EditAddressForm = {
  address2: '',
  telephoneNumber: '',
  faxNumber: '',
  endDate: '',
  invoicesReceiving: false,
  insuranceBillType: false,
  patientBillType: false,
  clientBillType: false,
  hasInitialBillType: false,
};
const insuranceValidator = (
  _value: boolean | undefined,
  { parent: values }: { parent: EditAddressForm }
) => {
  const initiallyDoesNotHaveBillType = !values.hasInitialBillType;

  if (initiallyDoesNotHaveBillType) {
    return true;
  }
  const billTypeIsSet =
    values.patientBillType || values.clientBillType || values.insuranceBillType;
  return !!billTypeIsSet;
};
const useValidationSchema = () => {
  const { t } = useTranslate('auth');
  return useMemo(() => {
    return yup.object().shape<YupShape<EditAddressForm>>({
      telephoneNumber: phoneNotRequiredValidator(t),
      faxNumber: phoneNotRequiredValidator(t),
      endDate: yup.string().nullable(),
      insuranceBillType: yup
        .boolean()
        .test(
          'bill-type-validation',
          t('AT_LEAST_ONE_BILL_TYPE_MUST_BE_TRUE'),
          insuranceValidator
        ),
      patientBillType: yup
        .boolean()
        .test(
          'bill-type-validation',
          t('AT_LEAST_ONE_BILL_TYPE_MUST_BE_TRUE'),
          insuranceValidator
        ),
      clientBillType: yup
        .boolean()
        .test(
          'bill-type-validation',
          t('AT_LEAST_ONE_BILL_TYPE_MUST_BE_TRUE'),
          insuranceValidator
        ),
    });
  }, [t]);
};

const styles = {
  warning: {
    svg: { flexShrink: 0 },
    gap: 10,
  },
  dummy: {
    flex: '1 1 100%',
    display: { xs: 'none', sm: 'block' },
  },
};
// todo [tech-debt]: make it less error prone by moving logic of the dialog & stuff into a separate component
//  so everytime dialog will open - it will also mount the logic component. This way a developer will be free
//  from having any checks if dialog is open. If the code of the logic works - then dialog is open.
//  It addresses the common issue with dialogs - often, even if dialog is closed, its code continue to work - while it shouldn't.
export const EditAddressDialog = ({
  selectedAddress,
}: {
  selectedAddress?: CompanyAddress;
}) => {
  const { t } = useTranslate('companies');
  const validationSchema = useValidationSchema();
  const { dialogOpened, errors, resetErrors, submit, closeDialog } =
    useEditAddressForm(selectedAddress?.id);
  const b = useBreakpoints();
  const geoValidated = !!selectedAddress?.googlePlaceId;
  const [updatedInitialValues, setInitialValues] =
    useState<EditAddressForm>(initialValues);

  useEffect(() => {
    if (!dialogOpened) {
      setInitialValues(initialValues);
    }
  }, [dialogOpened]);
  useLayoutEffect(() => {
    if (!selectedAddress || !dialogOpened) {
      return;
    }
    setInitialValues((val) => ({
      ...val,
      ...(selectedAddress as EditAddressForm),
      hasInitialBillType:
        selectedAddress.insuranceBillType ||
        selectedAddress.clientBillType ||
        selectedAddress.patientBillType ||
        false,
    }));
  }, [dialogOpened, selectedAddress]);

  const renderWarning = (type?: 'mobile') => {
    const warningContent = (
      <>
        <Box
          component={IconWarning}
          size={18}
          sx={{ color: (t) => t.palette.content.additional.default }}
        />
        <Typography variant={'14_18_500'} color={'grey.600'}>
          {t('WARN_ADDRESS_HAS_END_DATE')}
        </Typography>
      </>
    );
    const warning =
      type === 'mobile' ? (
        <Stack
          direction={'row'}
          sx={[
            styles.warning,
            {
              display: { xs: 'flex', sm: 'none' },
              pt: { xs: 24 },
            },
          ]}
        >
          {warningContent}
        </Stack>
      ) : (
        <Stack direction={'row'} sx={styles.warning}>
          {warningContent}
        </Stack>
      );

    return warning;
  };

  const { yearFrom, yearTo } = useMemo(() => {
    const now = DateTime.now();
    const future = now.plus({ year: 100 });
    return {
      yearFrom: now.year,
      yearTo: future.year,
    };
  }, []);

  return (
    <Dialog
      open={!!dialogOpened}
      size={b.smOnly ? 'xl' : 'xxl'}
      onClose={closeDialog}
    >
      <DialogTitle onClose={closeDialog}>{t('EDIT_ADDRESS')}</DialogTitle>
      <DialogContent sx={{ paddingY: { xs: 18 } }}>
        <FormikRedux<EditAddressForm>
          validationSchema={validationSchema}
          initialValues={updatedInitialValues}
          validateOnChange
          onSubmit={submit}
          serverErrors={errors ?? {}}
          resetServerErrors={resetErrors}
          enableReinitialize
          id={'edit_address_form'}
        >
          {(formikProps) => {
            const { submitCount, errors, values, touched, isValid } =
              formikProps;
            const billTypeError = !isValid
              ? getErrorForDisplay({
                  submitCount,
                  error: errors.insuranceBillType,
                  value: values.insuranceBillType,
                  touched: !!touched.insuranceBillType,
                })
              : '';
            return (
              <Stack gap={12}>
                {geoValidated ? (
                  <GrayLayout sx={{ gap: 24 }}>
                    <AddressLookup
                      readonly
                      initialValue={{
                        googlePlaceId: selectedAddress?.googlePlaceId,
                      }}
                      placeholder={t('ENTER_YOUR_COMPANY_ADDRESS')}
                    />
                    <InputControlF
                      name={'address2'}
                      max={55}
                      placeholder={t('ENTER_APARTMENT_SUITE_OR_UNIT')}
                    />
                  </GrayLayout>
                ) : (
                  <GrayLayout
                    sx={{
                      gap: 24,
                      paddingY: { xs: 12 },
                      dt: { fontWeight: 700 },
                    }}
                  >
                    <Grid
                      container
                      columnSpacing={{ xs: 42 }}
                      sx={{ 'dd, dt': { fontSize: { lg: '14px' } } }}
                    >
                      <Grid xs={12} sm={6} md={5} mb={{ xs: 12, sm: 0 }}>
                        <Dl>
                          <dt>{t('CITY')}</dt>
                          <dd>{selectedAddress?.city}</dd>
                        </Dl>
                        <Dl>
                          <dt>{t('STATE')}</dt>
                          <dd>{selectedAddress?.state}</dd>
                        </Dl>
                        <Dl>
                          <dt>{t('POSTAL_CODE')}</dt>
                          <dd>{selectedAddress?.postalCode}</dd>
                        </Dl>
                      </Grid>
                      <Grid xs={12} sm={6} md={7}>
                        <Stack
                          sx={{ height: { xs: '100%' } }}
                          justifyContent={'space-between'}
                        >
                          <Dl>
                            <dt>{t('ADDRESS_1')}</dt>
                            <dd>{selectedAddress?.address1}</dd>
                          </Dl>
                          <InputControlF
                            name={'address2'}
                            compact={b.xsOnly}
                            max={55}
                            label={t('ADDRESS_2')}
                            placeholder={t('ENTER_APARTMENT_SUITE_OR_UNIT')}
                            sx={{ mt: { xs: 12 }, flexGrow: 0 }}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </GrayLayout>
                )}
                <GrayLayout
                  sx={{ paddingTop: { xs: 36 }, paddingBottom: { xs: 24 } }}
                >
                  <Grid
                    container
                    columnSpacing={{ xs: 42 }}
                    rowSpacing={{
                      xs: 42,
                      sm: 42,
                    }}
                  >
                    <Grid xs={12} sm={6} md={5}>
                      <DateControlF
                        ignoreLabelHeight
                        name={'endDate'}
                        label={t('END_DATE')}
                        disablePastDates
                        sx={{ flex: '1 1 100%' }}
                        yearFrom={yearFrom}
                        yearTo={yearTo}
                      />
                      {formikProps.values.endDate && renderWarning('mobile')}
                    </Grid>
                    <Grid xs={12} sm={6} md={7}>
                      <PhoneInputControlF
                        ignoreLabelHeight
                        submitCount={submitCount}
                        name={'telephoneNumber'}
                        label={t('PHONE_NUMBER')}
                      />
                    </Grid>
                    <Grid
                      xs={12}
                      sm={6}
                      md={5}
                      sx={{
                        display: {
                          xs: 'none',
                          sm: 'flex',
                        },
                      }}
                    >
                      {formikProps.values.endDate && renderWarning()}
                    </Grid>
                    <Grid xs={12} sm={6} md={7}>
                      <PhoneInputControlF
                        ignoreLabelHeight
                        submitCount={submitCount}
                        name={'faxNumber'}
                        label={t('FAX_NUMBER')}
                      />
                    </Grid>
                  </Grid>
                </GrayLayout>
                <GrayLayout sx={{ paddingY: { xs: 18 } }}>
                  <BillTypesControls error={billTypeError} />
                  <SwitchControlF
                    label={t('INVOICES_RECEIVING')}
                    sx={{
                      height: { xs: 28 },
                      mb: { xs: 12 },
                      mt: { xs: 24 },
                      justifyContent: 'start',
                      gap: 16,
                    }}
                    name={'invoicesReceiving'}
                  />
                  <InvoiceReceivingMessage />
                </GrayLayout>
              </Stack>
            );
          }}
        </FormikRedux>
      </DialogContent>
      <DialogActions spaceBetween>
        <Button variant="outlined" color={'secondary'} onClick={closeDialog}>
          {t('CANCEL')}
        </Button>
        <Button color="secondary" type={'submit'} form={'edit_address_form'}>
          {t('APPLY')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const InvoiceReceivingMessage = () => {
  const { t } = useTranslate('companies');
  return (
    <Box
      display={'flex'}
      gap={{ xs: 4 }}
      sx={{ color: (t) => t.palette.content.additional.default }}
    >
      <Box
        component={IconSVGInformationMark}
        size={18}
        sx={{ flexShrink: 0 }}
      />
      <Typography sx={{ typography: { xs: '12_16_500' }, color: 'inherit' }}>
        {t('INVOICE_RECEIVING_MAIL_NOTIFICATION_TEXT')}
      </Typography>
    </Box>
  );
};
