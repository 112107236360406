/** @jsxImportSource @emotion/react */
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslate } from '@/i18n/useTranslate';
import { useDispatch } from 'react-redux';
import { useAuthState } from '@/store/auth/hooks';
import { Formik, FormikConfig, FormikProps, useFormikContext } from 'formik';
import { Grid, Link } from '@/components';
import { Button } from '@/components/Button';
import * as yup from 'yup';
import { phoneValidator } from '../../../common/validators';
import { Tab, TabPanel, Tabs, useTabs } from '@/components/TabPanel';
import { authActions } from '@/store/auth/slice';
import { Forms } from '../../../common/types';
import { useRecaptcha } from '@/hooks/useRecaptcha';
import { Box, Stack, Typography } from '@mui/material';
import { RecaptchaNote } from '@/components/RecaptchaNote';
import { AppRoutes } from '@/routes/appRoutes';
import { AuthHeader } from '@/features/authentication/common/AuthHeader';
import { InputControl } from '@/components/InputControl';
import { browserHistory } from '@/store/store';
import { PhoneInputControl } from '@/components/PhoneInputControl';
import { INPUT_TIP_HEIGHT } from '@/components/common/Common';
import { Dl } from '@/components/Dl';
import { useBreakpoints, formatNumber } from 'ui-kit';
import { useTranslatedErrors } from '@/hooks/useConnectServerValidationErrors';
import { EMAIL_ADDRESS_MAX_LENGTH } from '@/constants';
import { Notification } from '@/components/Notification';
const styles = {
  readonlyDl: {
    mb: { xs: '37px !important', sm: '24px !important' },
    dt: { fontWeight: 700 },
  },
};

export interface RecoveryCheckAccountFormState {
  phone?: string;
  email?: string;
  selected: 'phone' | 'email';
}
const useValidationSchema = () => {
  const { t } = useTranslate('auth');
  return useMemo(() => {
    return yup.object().shape(
      {
        phone: yup.string().when('email', {
          is: (email: string) => !email?.length,
          then: phoneValidator(t),
        }),
        email: yup.string().when('phone', {
          is: (phone: string) => !phone?.length,
          then: yup
            .string()
            .trim()
            .required(t('EMAIL_IS_REQUIRED'))
            .email(t('INVALID_EMAIL')),
        }),
      },
      [['email', 'phone']]
    );
  }, [t]);
};
export const CheckAccountFragment: FC<{
  notFound: boolean;
  reset: () => void;
}> = ({ notFound, reset }) => {
  const dispatch = useDispatch();

  const formikRef = useRef<FormikProps<RecoveryCheckAccountFormState>>(null);
  const {
    fetchingDeprecated: fetching,
    forms,
    serverValidation,
  } = useAuthState();
  const { t } = useTranslate('auth');
  useRecaptcha();
  const schema = useValidationSchema();
  const translatedErrors = useTranslatedErrors(serverValidation.fieldErrors);
  const submitForm: FormikConfig<RecoveryCheckAccountFormState>['onSubmit'] =
    useCallback(
      async (formData, formikHelpers) => {
        if (formData.selected === 'email') {
          delete formData.phone;
        } else {
          delete formData.email;
        }
        dispatch(authActions.recoveryCheckAccount(formData));
        formikHelpers.setSubmitting(true);
      },
      [dispatch]
    );

  useEffect(() => {
    window.setTimeout(() => {
      formikRef.current?.setSubmitting(fetching);
    });
  }, [fetching]);
  useEffect(() => {
    formikRef.current?.setErrors({
      ...formikRef.current?.errors,
      ...translatedErrors,
    });
  }, [t, translatedErrors]);
  return (
    <Formik<RecoveryCheckAccountFormState>
      innerRef={formikRef}
      initialValues={{
        email: forms[Forms.RecoveryCheckAccountForm]?.email ?? '',
        phone: forms[Forms.RecoveryCheckAccountForm]?.phone ?? '',
        selected: forms[Forms.RecoveryCheckAccountForm]?.selected ?? 'phone',
      }}
      onSubmit={submitForm}
      validateOnChange={true}
      validationSchema={schema}
    >
      <FormView accountNotFound={notFound} reset={reset} />
    </Formik>
  );
};
export const FormView: FC<{ accountNotFound: boolean; reset: () => void }> = ({
  accountNotFound,
  reset,
}) => {
  const { t } = useTranslate('auth');
  const dispatch = useDispatch();
  const { serverValidation } = useAuthState();
  const b = useBreakpoints();
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormikContext<RecoveryCheckAccountFormState>();
  const handleChangeWithValidationReset = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      if (serverValidation.fieldErrors[name]) {
        dispatch(authActions.resetFieldValidationError(name));
      }
      handleChange(e);
    },
    [dispatch, handleChange, serverValidation.fieldErrors]
  );
  const { index, handleChange: tabChange } = useTabs(
    values.selected === 'email' ? 1 : 0,
    (tabIndex) => {
      const tab = tabIndex === 0 ? 'phone' : 'email';
      void setFieldValue('selected', tab);
      void setFieldValue(tab === 'phone' ? 'email' : 'phone', '');
    }
  );
  return (
    <Stack width={'100%'} justifyContent={{ xs: 'space-between' }}>
      <Stack width={'100%'}>
        <Stack component={'form'} id="recovery_form" onSubmit={handleSubmit}>
          <AuthHeader
            title={t('ACCOUNT_RECOVERY')}
            sx={{ mb: { xs: 24, sm: 66, lg: 48 } }}
          >
            <Link
              to={{ pathname: AppRoutes.AUTH, search: window.location.search }}
            >
              {t('SIGN_IN')}
            </Link>
          </AuthHeader>
          <Box>
            <Typography
              variant={b.xsOnly ? '14_18_500' : '16_20_500'}
              mb={{ xs: 24, sm: 180, md: 90 }}
            >
              {t('TAKE_INTO_ACCOUNT_')}
            </Typography>
          </Box>
          <Tabs
            value={index}
            onChange={tabChange}
            variant={'fullWidth'}
            sx={{ mb: { xs: 36, sm: 48 } }}
          >
            <Tab label={t('MOBILE_PHONE')} disabled={accountNotFound} />
            <Tab label={t('EMAIL')} disabled={accountNotFound} />
          </Tabs>
          <Box
            mb={{
              xs: 48,
              sm: 96 - INPUT_TIP_HEIGHT,
              lg: 60,
            }}
          >
            <TabPanel value={0} selected={index}>
              <Grid container columnSpacing={20}>
                <Grid xs={12} sm={6} lg={12}>
                  {accountNotFound ? (
                    <Dl sx={styles.readonlyDl}>
                      <dt>{t('MOBILE_PHONE')}</dt>
                      <dd>{formatNumber(values.phone, true)}</dd>
                    </Dl>
                  ) : (
                    <PhoneInputControl
                      label={t('MOBILE_PHONE')}
                      name={'phone'}
                      compact
                      value={values.phone ?? ''}
                      onChange={handleChangeWithValidationReset}
                      error={touched.phone ? errors.phone : undefined}
                      disabled={accountNotFound}
                    />
                  )}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={1} selected={index}>
              <Grid container columnSpacing={20}>
                <Grid xs={12} sm={6} lg={12}>
                  {accountNotFound ? (
                    <Dl sx={styles.readonlyDl}>
                      <dt>{t('EMAIL')}</dt>
                      <dd>{values.email}</dd>
                    </Dl>
                  ) : (
                    <InputControl
                      label={t('EMAIL')}
                      type={'text'}
                      name={'email'}
                      placeholder={t('ENTER_EMAIL')}
                      value={values.email ?? ''}
                      compact
                      onChange={handleChangeWithValidationReset}
                      error={touched.email && errors.email}
                      disabled={accountNotFound}
                      max={EMAIL_ADDRESS_MAX_LENGTH}
                    />
                  )}
                </Grid>
              </Grid>
            </TabPanel>
            {accountNotFound && (
              <Grid
                container
                columnSpacing={20}
                mb={{ xs: -12, sm: 0 }}
                mt={12}
              >
                <Grid xs={12} sm={6} lg={12}>
                  <Notification type={'error'} sx={{ alignItems: 'center' }}>
                    {t('ACCOUNT_NOT_FOUND!')}
                  </Notification>
                </Grid>
              </Grid>
            )}
          </Box>
        </Stack>
        {accountNotFound ? (
          <Stack gap={12}>
            <Button color={'secondary'} onClick={reset}>
              {t('TO_ACCOUNT_RECOVERY_PAGE')}
            </Button>
            <Button
              color={'secondary'}
              variant={'outlined'}
              onClick={() => {
                reset();
                browserHistory.push(AppRoutes.AUTH);
              }}
            >
              {t('TO_MAIN_PAGE')}
            </Button>
          </Stack>
        ) : (
          <Button
            disabled={isSubmitting}
            fullWidth
            color={'secondary'}
            type="submit"
            form="recovery_form"
            sx={{ mb: { xs: 12 }, flexShrink: 0 }}
          >
            {t('REQUEST_RECOVERY')}
          </Button>
        )}
      </Stack>
      <RecaptchaNote />
    </Stack>
  );
};
