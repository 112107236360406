import IconSVGHamburger from 'ui-kit/lib/icons/24/Menu/BurgerMenu';
import IconSVGTimesMenu from 'ui-kit/lib/icons/24/Action/Close';
import { IconButton } from '@/components/IconButton';
export const HamburgerButton = ({
  toggle,
  open,
}: {
  toggle: () => void;
  open: boolean;
}) => {
  return (
    <IconButton onClick={toggle} size={'lg'} color={'quaternary'}>
      {open ? <IconSVGTimesMenu /> : <IconSVGHamburger />}
    </IconButton>
  );
};
