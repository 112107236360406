import { forwardRef } from 'react';
import IconQuestionMark from 'ui-kit/lib/icons/24/Warning/Question';
import { IconButton } from '@/components/IconButton';

export const QuestionMarkButton = forwardRef<
  HTMLButtonElement,
  { onClick: React.MouseEventHandler }
>((props, ref) => {
  return (
    <IconButton ref={ref} color={'quaternary'} {...props} size={'lg'}>
      <IconQuestionMark />
    </IconButton>
  );
});
QuestionMarkButton.displayName = 'Bt';
