import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { insuranceActions, InsuranceState } from '@/store/insurance/slice';
import { SagaPayload, SagaReturn } from '@/store/common/types';
import { showError500OrUnknownToast } from '@/store/common/showError500OrUnknownToast';
import { RootState } from '@/store/store';
import { client } from '@/api/client/ApiClient';
import { companiesActions } from '@/store/companies/slice';
import { withPermissionDigestForCompany } from '@/api/client/interceptors';
type Action<T extends keyof typeof insuranceActions> = SagaPayload<
  (typeof insuranceActions)[T]
>;

export function* insuranceSaga() {
  yield all([
    takeLatest(insuranceActions.requestAllCarriers, requestAllCarriers),
    takeLatest(insuranceActions.requestListLoadMore, requestListLoadMore),
    takeLatest(insuranceActions.requestCompanyCarriers, requestCompanyCarriers),
    takeLatest(
      insuranceActions.requestAddCarrierToCompany,
      requestAddCarrierToCompany
    ),
    takeLatest(
      insuranceActions.requestRemoveCarrierFromCompany,
      requestRemoveCarrierFromCompany
    ),
  ]);
}
export const MAX_PAGES = 30;

function* requestAllCarriers({
  payload,
}: Action<'requestAllCarriers'>): SagaReturn {
  yield put(insuranceActions.setFetching({ main: true, isFetching: true }));
  try {
    const {
      data: carriersPage,
    }: Awaited<ReturnType<typeof client.searchInsuranceCarriers>> = yield call(
      client.searchInsuranceCarriers,
      {
        filter: payload.filter,
        startPage: 0,
        perPage: MAX_PAGES,
      }
    );
    yield put(
      insuranceActions.setPageInfo({
        filter: payload.filter,
        pageIndex: 0,
        hasNext: carriersPage.hasNext,
      })
    );
    yield put(
      insuranceActions.setListOfCarriers({
        carriers: carriersPage.items,
        replace: true,
      })
    );
  } catch (e) {
    showError500OrUnknownToast(e);
  } finally {
    yield put(insuranceActions.setFetching({ main: true, isFetching: false }));
  }
}

function* requestListLoadMore(): SagaReturn {
  yield put(insuranceActions.setFetching({ main: true, isFetching: false }));
  const state: InsuranceState = yield select((rs: RootState) => rs.insurance);
  if (!state.pageInfo.hasNext) {
    return;
  }
  try {
    const {
      data: carriersPage,
    }: Awaited<ReturnType<typeof client.searchInsuranceCarriers>> = yield call(
      client.searchInsuranceCarriers,
      {
        filter: state.pageInfo.filter,
        startPage: state.pageInfo.pageIndex + 1,
        perPage: MAX_PAGES,
      }
    );
    yield put(
      insuranceActions.setPageInfo({
        filter: state.pageInfo.filter,
        pageIndex: state.pageInfo.pageIndex + 1,
        hasNext: carriersPage.hasNext,
      })
    );
    yield put(
      insuranceActions.setListOfCarriers({
        carriers: carriersPage.items,
        replace: false,
      })
    );
  } catch (e) {
    showError500OrUnknownToast(e);
  } finally {
    yield put(insuranceActions.setFetching({ main: true, isFetching: false }));
  }
}

function* requestCompanyCarriers({
  payload,
}: Action<'requestCompanyCarriers'>): SagaReturn {
  yield put(
    insuranceActions.setFetching({
      companyId: payload.companyId,
      isFetching: true,
    })
  );

  try {
    const {
      data: carriers,
    }: Awaited<ReturnType<typeof client.getCompanyInsuranceCarriers>> =
      yield call(
        client.getCompanyInsuranceCarriers,
        payload.companyId,
        withPermissionDigestForCompany(payload.companyId)
      );

    yield put(
      insuranceActions.setCompanyCarriers({
        carriers,
        companyId: payload.companyId,
        replace: true,
      })
    );
  } catch (e) {
    showError500OrUnknownToast(e);
  } finally {
    yield put(
      insuranceActions.setFetching({
        companyId: payload.companyId,
        isFetching: false,
      })
    );
  }
}

function* requestAddCarrierToCompany({
  payload,
}: Action<'requestAddCarrierToCompany'>): SagaReturn {
  const state: InsuranceState = yield select((s: RootState) => s.insurance);

  const insuranceCarrier =
    state.carriers.listOfAll.data.byID[payload.carrierId];
  if (insuranceCarrier)
    yield put(
      insuranceActions.setCompanyCarriers({
        carriers: [{ id: insuranceCarrier.id, insuranceCarrier }],
        companyId: payload.companyId,
        replace: false,
      })
    );
  try {
    const {
      data: carrier,
    }: Awaited<ReturnType<typeof client.createCompanyInsuranceCarrier>> =
      yield call(client.createCompanyInsuranceCarrier, payload.companyId, {
        insuranceCarrierId: payload.carrierId,
      });
    yield put(insuranceActions.removeCarrierFromCompany(payload));
    yield put(
      insuranceActions.setCompanyCarriers({
        carriers: [carrier],
        companyId: payload.companyId,
        replace: false,
      })
    );
  } catch (e) {
    showError500OrUnknownToast(e);
  }
}

function* requestRemoveCarrierFromCompany({
  payload,
}: Action<'requestRemoveCarrierFromCompany'>): SagaReturn {
  const state: InsuranceState = yield select((s: RootState) => s.insurance);
  const companyCarriersCount =
    state.carriers.ofCompanies[payload.companyId]?.data.allIDs.length;
  const removingLastOne = companyCarriersCount === 1;
  if (removingLastOne && !state.removeConfirmationDialog) {
    yield put(
      insuranceActions.setRemoveConfirmationDialog({
        carrierId: payload.carrierId,
      })
    );
    return;
  } else {
    yield put(insuranceActions.setRemoveConfirmationDialog(false));
  }
  try {
    yield put(insuranceActions.removeCarrierFromCompany(payload));
    yield call(
      client.removeCompanyInsuranceCarrier,
      payload.companyId,
      payload.carrierId
    );
    if (removingLastOne) {
      yield put(
        companiesActions.requestCompanyDetails({
          companyId: payload.companyId,
          quiet: true,
          forced: true,
        })
      );
    }
  } catch (e) {
    yield call(requestCompanyCarriers, {
      payload: {
        companyId: payload.companyId,
      },
    });
    showError500OrUnknownToast(e);
  }
}
