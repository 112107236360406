import { FC, useCallback, useRef, useState } from 'react';
import { Box, Stack, SxProps, Theme } from '@mui/material';
import { AddressInfo, GoogleAutocomplete, GoogleMap } from 'ui-kit/lib/address';

export interface AddressLookupProps {
  sx?: SxProps<Theme>;
  onChange?: (addr: AddressInfo | null) => void;
  error?: string;
  initialValue?: { addressString?: string; googlePlaceId?: string };
  readonly?: boolean;
  placeholder: string;
  name?: string;
}

export const AddressLookup: FC<AddressLookupProps> = ({
  placeholder,
  initialValue,
  readonly,
  sx,
  onChange,
  error,
}) => {
  const [address, setAddress] = useState<string | null | undefined>(
    initialValue?.addressString
  );
  const resetHandlerRef = useRef({ reset: () => {} });
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;
  const handleAutocomplete = useCallback((addr: AddressInfo | null) => {
    if (addr?.formattedAddress) {
      setAddress(addr.formattedAddress);
    }
    onChangeRef.current?.(addr);
  }, []);

  return (
    <>
      <Stack
        sx={sx}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        {!readonly && (
          <Box sx={{ pb: { xs: 24 } }}>
            <GoogleAutocomplete
              onChange={handleAutocomplete}
              error={error}
              placeholder={placeholder}
              value={initialValue?.addressString}
              placeId={initialValue?.googlePlaceId}
            />
          </Box>
        )}
        <GoogleMap
          value={address ?? initialValue?.addressString}
          placeId={address ? undefined : initialValue?.googlePlaceId}
          innerRef={resetHandlerRef}
        />
      </Stack>
    </>
  );
};
