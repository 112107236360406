import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  styled,
  SxProps,
  Theme,
  Tooltip,
  TooltipProps,
  Typography,
  TypographyTypeMap,
} from '@mui/material';
import { alpha } from '@/theme/utils';
import { useTranslate } from '@/i18n/useTranslate';
import { spreadSx } from '@/utils/spreadSx';
import { FieldSize } from '@/components/common/FieldSize';

const sizeStyles = {
  tipWrapper: {
    sm: {
      height: 16,
    },
    md: {
      height: 24,
    },
    lg: {
      height: 24,
    },
  },
  tipWrapperNegativeCompensation: {
    sm: {
      marginBottom: -16,
    },
    md: {
      marginBottom: -24,
    },
    lg: {
      marginBottom: -24,
    },
  },
};
const sizeVariants: Record<FieldSize, TypographyTypeMap['props']['variant']> = {
  sm: '12_16_500',
  md: '12_16_500',
  lg: '14_18_500',
};
const isEllipsisActive = (e: HTMLElement | null) => {
  if (!e) {
    return false;
  }
  const tolerance = 2;
  return e.offsetWidth + tolerance < e.scrollWidth;
};
export const InputTip: FC<{
  color: 'success' | 'error' | 'default';
  disabled?: boolean;
  children: string | undefined | null | boolean;
  hide: boolean | undefined;
  className?: string;
  sx?: SxProps<Theme>;
  size?: FieldSize;
  hasHeight?: boolean;
}> = ({
  hasHeight,
  size = 'md',
  sx,
  hide,
  color,
  children,
  disabled,
  className,
}) => {
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const [withTooltip, setWithTooltip] = useState(false);
  const { t } = useTranslate('common');
  useEffect(() => {
    if (ref && isEllipsisActive(ref)) {
      setWithTooltip(true);
    } else {
      setWithTooltip(false);
    }
  }, [children, ref]);

  if (hide) {
    return null;
  }

  const getColor = (t: Theme) =>
    disabled
      ? t.palette.grey[400]
      : color === 'error'
        ? t.palette.error.main
        : color === 'success'
          ? t.palette.success.main
          : undefined;

  const tip = (
    <StyledInputTip
      sx={[
        { cursor: withTooltip && color === 'error' ? 'pointer' : '' },
        ...spreadSx(sx),
        sizeStyles.tipWrapper[size],
        hasHeight ? {} : sizeStyles.tipWrapperNegativeCompensation[size],
      ]}
      className={className}
      color={getColor}
    >
      <Typography
        ref={(r) => setRef(r as HTMLElement | null)}
        variant={sizeVariants[size]}
      >
        {typeof children === 'string' ? children : null}
      </Typography>
    </StyledInputTip>
  );

  if (withTooltip && color === 'error') {
    return (
      <AdjustedTooltip
        title={
          <>
            <strong>{t('ERROR')}:</strong>&nbsp; {children}
          </>
        }
      >
        {tip}
      </AdjustedTooltip>
    );
  }

  return tip;
};

interface TtProps extends TooltipProps {}
const AdjustedTooltip: FC<TtProps> = ({ children, sx, ...restProps }) => {
  return (
    <Tooltip
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      arrow
      placement={'bottom-end'}
      componentsProps={{
        arrow: {
          sx: [
            (t) => ({
              right: 0,
              left: 'initial !important',
              transform: 'translateX(-18px) !important',
              color: alpha(t.palette.error.main, 20),
            }),
          ],
        },
        tooltip: {
          sx: [
            (t) => ({
              backgroundColor: alpha(t.palette.error.main, 20),
              color: t.palette.grey[700],
              paddingX: 10,
              paddingY: 6,
              maxWidth: 260,
              marginTop: '10px !important',
              fontSize: '12px',
              lineHeight: '19px',
              fontWeight: 500,
            }),
          ],
        },
      }}
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
};
interface Pps {
  color?: (t: Theme) => string | undefined;
}
export const StyledInputTip = styled(Box)<Pps>(({ color, theme: t }) => {
  return {
    span: {
      position: 'absolute',
      left: 0,
      right: 20,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      color: color?.(t) ?? t.palette.grey[700],
      whiteSpace: 'nowrap',
      minWidth: 10,
    },
    textAlign: 'start',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
  };
});
