import React, { FC } from 'react';
import { useTranslate } from '@/i18n';
import { Box, Stack, styled, SxProps, Theme, Typography } from '@mui/material';
// todo: should be from icon set - but it does not have it yet
import IconSVGCloudUpload from 'ui-kit/lib/icons/24/System/CloudUpload';
import IconSVGFilePlus from 'ui-kit/lib/icons/24/Action/FilePlus';
import classNames from 'classnames';

export const DnDZoneViewPhotoUpload: FC<{
  label?: string;
  active?: boolean;
  src?: string;
  round?: boolean;
}> = ({ round = true, src, active, ...restProps }) => {
  const { t } = useTranslate('common');
  const validSrc = src || undefined;
  return (
    <DnDLabel
      className={classNames(
        active && 'dd-active',
        validSrc && 'dd-image',
        'dd-label'
      )}
      {...restProps}
      sx={{
        border: (t) => (validSrc ? 'none' : `1px solid ${t.palette.grey[300]}`),
      }}
      round={round}
    >
      <Img src={validSrc} />
      {!!validSrc && <Backdrop sx={styles.backdrop} />}
      <Stack sx={styles.box}>
        {!active && (
          <>
            <Box
              component={IconSVGCloudUpload}
              size={48}
              sx={styles.cloudUploadIcon}
            />
            <Typography
              sx={styles.title}
              variant={'14_20_400'}
              dangerouslySetInnerHTML={{
                __html: t('DROP_PHOTO_TO_UPLOAD_OR_BROWSE'),
              }}
            />
          </>
        )}
        <Box component={IconSVGFilePlus} sx={styles.filePlus} />
      </Stack>
    </DnDLabel>
  );
};

const styles = {
  filePlus: {
    display: 'none',
    '.dd-active & ': { display: 'block' },
    color: 'common.white',
  },
  backdrop: { '.dd-label:hover &, .dd-active &': { opacity: 0.4 } },
  box: {
    justifyContent: 'center',
    alignItems: 'center',
    gap: 17,
    zIndex: 101,
  },
  cloudUploadIcon: {
    color: (t) => t.palette.grey[400],
    '.dd-label:hover &': { display: 'block' },
    '.dd-image &': {
      display: 'none',
      path: {
        fill: '#fff',
      },
    },
  },
  title: {
    lineHeight: '20px',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    strong: { fontWeight: 500 },
    '.dd-label:hover &': { display: 'block' },
    '.dd-image &': { color: '#fff', display: 'none' },
  },
} satisfies Record<string, SxProps<Theme>>;

const DnDLabel = styled('label')<{ round?: boolean }>(
  ({ theme: th, round }) => ({
    position: 'relative',
    width: 196,
    height: 196,
    overflow: 'hidden',
    borderRadius: round ? '50%' : '',
    boxSizing: 'border-box',
    cursor: 'pointer',
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 17,
    backgroundColor: th.palette.grey[200],
    alignItems: 'center',
    '*': {
      pointerEvents: 'none',
    },
    '&.dd-active': {
      background: `${th.palette.grey[300]} !important`,
      justifyContent: 'center',
    },
    '&:hover': {
      background: th.palette.grey[300],
      'svg path': {
        fill: 'white',
      },
    },
    'input:focus-visible + &': {
      background: `${th.palette.grey[300]} !important`,
      border: `1px solid ${th.colors.all.focus}`,
      'svg path': {
        fill: 'white',
      },
    },
  })
);

const Backdrop = styled(Box)(() => ({
  opacity: 0,
  backgroundColor: '#000',
  position: 'absolute',
  height: '100%',
  width: '100%',
  zIndex: 100,
}));
const Img = styled(Box)<{ src: string | undefined }>(({ src }) => ({
  position: 'absolute',
  height: '100%',
  width: '100%',
  backgroundImage: `url(${src})`,
  backgroundPosition: `center center`,
  backgroundRepeat: `no-repeat`,
  backgroundSize: 'cover',
}));
