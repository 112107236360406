import { useTranslate } from '@/i18n/useTranslate';
import { Scrollbar, useBoxBreakpoints } from 'ui-kit';
import { Stack } from '@mui/material';
import {
  BoxHeader,
  boxPaddingsSx,
  boxXPaddings,
  boxYPaddings,
} from '@/components';
import { Tab, TabPanel, Tabs } from '@/components/TabPanel';
import React, { memo, ReactNode, useState } from 'react';
import { CONTENT_BOX_ID } from '@/features/layouts/MainLayout/MainLayout';
import { TabContent } from '@/features/module/LIMS/fragments/TabContent';
import { UUID } from '@/types';
import { useSelectedPermissions } from '../hooks/useSelectedPermissions';
import { useParams } from '@/routes/appRoutes.ts';
import { usePermissionsSelector } from '@/features/module/LIMS/hooks/usePermissionsSelector.ts';
import { Skeleton } from '@/components/Skeleton';
export const SelectedPermissionsFragment = memo(
  ({
    showNode,
    readOnly,
    buttons,
  }: {
    showNode?: (uuid: UUID) => void;
    readOnly: boolean;
    buttons?: ReactNode;
  }) => {
    const { data, initLoading } = useSelectedPermissions();
    const params = useParams();
    const { selected } = usePermissionsSelector(params);
    const { t } = useTranslate('module');
    const b = useBoxBreakpoints(CONTENT_BOX_ID, ['md']);
    const [tab, setTab] = useState(1);
    const showButtons = !readOnly;
    const initLoadingIsDone = !initLoading;
    return (
      <>
        <Stack sx={styles.rightTopSection}>
          <BoxHeader mb={18}>
            {t('SELECTED_PERMISSIONS_', { count: selected.length })}
          </BoxHeader>
          <Stack sx={{ height: 42 }}>
            <Tabs
              value={tab}
              variant={b.mdOnly ? 'fullWidth' : 'standard'}
              onChange={(_, t) => setTab(t)}
            >
              <Tab label={t('ACCESS')} value={1} />
              <Tab label={t('ACTION')} value={2} />
              <Tab label={t('DELETE')} value={3} />
              <Tab label={t('ROLE')} value={4} />
            </Tabs>
          </Stack>
        </Stack>
        <Scrollbar
          sx={[boxPaddingsSx, { paddingY: { xs: 18, sm: 24, lg: 24 } }]}
          shadow
        >
          {initLoading ? (
            <Skeleton variant={'article'} />
          ) : (
            <>
              <TabPanel value={1} selected={tab}>
                <TabContent data={data.ACCESS} onClick={showNode} />
              </TabPanel>
              <TabPanel value={2} selected={tab}>
                <TabContent data={data.ACTION} onClick={showNode} />
              </TabPanel>
              <TabPanel value={3} selected={tab}>
                <TabContent data={data.DELETE} onClick={showNode} />
              </TabPanel>
              <TabPanel value={4} selected={tab}>
                <TabContent data={data.ROLE} onClick={showNode} />
              </TabPanel>
            </>
          )}
        </Scrollbar>
        {showButtons && initLoadingIsDone && (
          <Stack direction={'row'} sx={styles.footer}>
            {buttons}
          </Stack>
        )}
      </>
    );
  }
);

SelectedPermissionsFragment.displayName = 'Spf';

const styles = {
  footer: {
    paddingX: boxXPaddings,
    paddingY: 24,
    justifyContent: 'end',
    gap: { xs: 12, lg: 12 },
  },
  rightColumn: () => ({
    width: '100%',
    minWidth: '200px',
  }),
  rightTopSection: {
    paddingX: boxXPaddings,
    paddingTop: boxYPaddings,
    paddingBottom: { xs: 18 },
  },
};
