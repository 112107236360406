/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AccountInviteVerificationDTO,
  ActivationRequest,
  BaseError,
  BaseResendRequest,
  CompanyAuthResponse,
  CookieWrapper,
  EmailConfirmRequest,
  EmailRecoveryCheckRequest,
  EmailRecoveryRequest,
  IntrospectTokenRequest,
  IntrospectTokenResponse,
  InvitationResultResponse,
  MFAConfirmRequest,
  MFAConflictResponse,
  MFAResendTimeResponse,
  ModuleType,
  OpenidSignInParams,
  PasswordRequest,
  PhoneConfirmRequest,
  PhoneRecoveryCheckRequest,
  PhoneRecoveryRequest,
  RegistrationRequest,
  RenewTokenRequest,
  ResendTimeResponse,
  RevokeTokenRequest,
  SignInMFARequest,
  SignInRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

import { ValidationError } from '@/types/ValidationError';

export class Api<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * @description Verify activation
   *
   * @tags Activation
   * @name verifyActivation
   * @summary Verify activation
   * @request GET:/api/v1/activation/{invite}
   * @docs https://passport-identity.test.private.lifedl.net/swagger-ui/index.html#Activation/verifyActivation
   * @response `200` `AccountInviteVerificationDTO` OK
   * @response `400` `ValidationError` Bad request
   * @response `500` `BaseError` Internal Server Error
   */
  verifyActivation = (invite: string, params: RequestParams = {}) =>
    this.request<AccountInviteVerificationDTO, ValidationError | BaseError>({
      path: `/api/v1/activation/${invite}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Confirm activation
   *
   * @tags Activation
   * @name confirmActivation
   * @summary Confirm activation
   * @request POST:/api/v1/activation/{invite}/confirm
   * @docs https://passport-identity.test.private.lifedl.net/swagger-ui/index.html#Activation/confirmActivation
   * @response `200` `InvitationResultResponse` OK
   * @response `400` `ValidationError` Bad request
   * @response `500` `BaseError` Internal Server Error
   */
  confirmActivation = (
    invite: string,
    data: PhoneConfirmRequest,
    params: RequestParams = {}
  ) =>
    this.request<InvitationResultResponse, ValidationError | BaseError>({
      path: `/api/v1/activation/${invite}/confirm`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Activation by email
   *
   * @tags Activation
   * @name activationByEmail
   * @summary Activation by email
   * @request POST:/api/v1/activation/{invite}/email
   * @docs https://passport-identity.test.private.lifedl.net/swagger-ui/index.html#Activation/activationByEmail
   * @response `200` `ResendTimeResponse` OK
   * @response `400` `ValidationError` Bad request
   * @response `500` `BaseError` Internal Server Error
   */
  activationByEmail = (
    invite: string,
    data: ActivationRequest,
    params: RequestParams = {}
  ) =>
    this.request<ResendTimeResponse, ValidationError | BaseError>({
      path: `/api/v1/activation/${invite}/email`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Activation by phone
   *
   * @tags Activation
   * @name activationByPhone
   * @summary Activation by phone
   * @request POST:/api/v1/activation/{invite}/phone
   * @docs https://passport-identity.test.private.lifedl.net/swagger-ui/index.html#Activation/activationByPhone
   * @response `200` `InvitationResultResponse` OK
   * @response `400` `ValidationError` Bad request
   * @response `500` `BaseError` Internal Server Error
   */
  activationByPhone = (
    invite: string,
    data: PasswordRequest,
    params: RequestParams = {}
  ) =>
    this.request<InvitationResultResponse, ValidationError | BaseError>({
      path: `/api/v1/activation/${invite}/phone`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Account authentication
   *
   * @tags Auth
   * @name accountSignIn
   * @summary Account sign in
   * @request POST:/api/v1/auth
   * @docs https://passport-identity.test.private.lifedl.net/swagger-ui/index.html#Auth/accountSignIn
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `403` `undefined` Forbidden
   * @response `423` `undefined` Locked
   * @response `428` `ResendTimeResponse` Precondition Required
   * @response `500` `BaseError` Internal Server Error
   */
  accountSignIn = (data: SignInRequest, params: RequestParams = {}) =>
    this.request<
      undefined,
      ValidationError | undefined | ResendTimeResponse | BaseError
    >({
      path: `/api/v1/auth`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Confirm account authentication
   *
   * @tags Auth
   * @name confirmAccountSignIn
   * @summary Confirm account sign in
   * @request POST:/api/v1/auth/2fa
   * @docs https://passport-identity.test.private.lifedl.net/swagger-ui/index.html#Auth/confirmAccountSignIn
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `500` `BaseError` Internal Server Error
   */
  confirmAccountSignIn = (
    data: PhoneConfirmRequest,
    params: RequestParams = {}
  ) =>
    this.request<undefined, ValidationError | BaseError>({
      path: `/api/v1/auth/2fa`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Module authentication
   *
   * @tags Auth
   * @name moduleSignIn
   * @summary Module sign in
   * @request POST:/api/v1/auth/companies/{companyId}/modules/{module}
   * @docs https://passport-identity.test.private.lifedl.net/swagger-ui/index.html#Auth/moduleSignIn
   * @secure
   * @response `200` `CompanyAuthResponse` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `403` `CompanyAuthResponse` Forbidden
   * @response `500` `BaseError` Internal Server Error
   */
  moduleSignIn = (
    companyId: string,
    module: ModuleType,
    params: RequestParams = {}
  ) =>
    this.request<
      CompanyAuthResponse,
      ValidationError | undefined | CompanyAuthResponse | BaseError
    >({
      path: `/api/v1/auth/companies/${companyId}/modules/${module}`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Account logout
   *
   * @tags Auth
   * @name accountLogout
   * @summary Account logout
   * @request POST:/api/v1/auth/logout
   * @docs https://passport-identity.test.private.lifedl.net/swagger-ui/index.html#Auth/accountLogout
   * @response `200` `undefined` OK
   * @response `500` `BaseError` Internal Server Error
   */
  accountLogout = (params: RequestParams = {}) =>
    this.request<undefined, BaseError>({
      path: `/api/v1/auth/logout`,
      method: 'POST',
      ...params,
    });
  /**
   * @description Account authentication with MFA
   *
   * @tags Auth
   * @name signInMfa
   * @summary Account sign in with MFA
   * @request POST:/api/v1/auth/mfa
   * @docs https://passport-identity.test.private.lifedl.net/swagger-ui/index.html#Auth/signInMFA
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `403` `undefined` Forbidden
   * @response `409` `MFAConflictResponse` Conflict
   * @response `423` `undefined` Locked
   * @response `428` `MFAResendTimeResponse` Precondition Required
   * @response `500` `BaseError` Internal Server Error
   */
  signInMfa = (data: SignInMFARequest, params: RequestParams = {}) =>
    this.request<
      undefined,
      | ValidationError
      | undefined
      | MFAConflictResponse
      | MFAResendTimeResponse
      | BaseError
    >({
      path: `/api/v1/auth/mfa`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Confirm account authentication with MFA
   *
   * @tags Auth
   * @name confirmSignInMfa
   * @summary Confirm account sign in with MFA
   * @request POST:/api/v1/auth/mfa/confirm
   * @docs https://passport-identity.test.private.lifedl.net/swagger-ui/index.html#Auth/confirmSignInMFA
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `409` `MFAConflictResponse` Conflict
   * @response `500` `BaseError` Internal Server Error
   */
  confirmSignInMfa = (data: MFAConfirmRequest, params: RequestParams = {}) =>
    this.request<undefined, ValidationError | MFAConflictResponse | BaseError>({
      path: `/api/v1/auth/mfa/confirm`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Sending confirmation code
   *
   * @tags Code
   * @name sendCode
   * @summary Send code
   * @request POST:/api/v1/code/send
   * @docs https://passport-identity.test.private.lifedl.net/swagger-ui/index.html#Code/sendCode
   * @response `200` `ResendTimeResponse` OK
   * @response `400` `ValidationError` Bad request
   * @response `500` `BaseError` Internal Server Error
   */
  sendCode = (data: BaseResendRequest, params: RequestParams = {}) =>
    this.request<ResendTimeResponse, ValidationError | BaseError>({
      path: `/api/v1/code/send`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Openid callback
   *
   * @tags Openid
   * @name openidCallback
   * @summary Openid callback
   * @request GET:/api/v1/openid/callback
   * @docs https://passport-identity.test.private.lifedl.net/swagger-ui/index.html#Openid/openidCallback
   * @secure
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `500` `BaseError` Internal Server Error
   */
  openidCallback = (params: RequestParams = {}) =>
    this.request<undefined, ValidationError | BaseError>({
      path: `/api/v1/openid/callback`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
   * @description Openid authentication
   *
   * @tags Openid
   * @name openidSignIn
   * @summary Openid sign in
   * @request POST:/api/v1/openid/company/{companyId}
   * @docs https://passport-identity.test.private.lifedl.net/swagger-ui/index.html#Openid/openidSignIn
   * @secure
   * @response `200` `CompanyAuthResponse` OK
   * @response `400` `ValidationError` Bad request
   * @response `401` `undefined` Unauthorized
   * @response `403` `undefined` Forbidden
   * @response `500` `BaseError` Internal Server Error
   */
  openidSignIn = (
    { companyId, ...query }: OpenidSignInParams,
    params: RequestParams = {}
  ) =>
    this.request<CompanyAuthResponse, ValidationError | undefined | BaseError>({
      path: `/api/v1/openid/company/${companyId}`,
      method: 'POST',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Pre recovery by email
   *
   * @tags Recovery
   * @name preRecoveryByEmail
   * @summary Pre recovery by email
   * @request POST:/api/v1/recovery/email
   * @docs https://passport-identity.test.private.lifedl.net/swagger-ui/index.html#Recovery/preRecoveryByEmail
   * @response `200` `ResendTimeResponse` OK
   * @response `400` `ValidationError` Bad request
   * @response `403` `undefined` Forbidden
   * @response `423` `undefined` Locked
   * @response `500` `BaseError` Internal Server Error
   */
  preRecoveryByEmail = (
    data: EmailRecoveryRequest,
    params: RequestParams = {}
  ) =>
    this.request<ResendTimeResponse, ValidationError | undefined | BaseError>({
      path: `/api/v1/recovery/email`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Check account exists by email
   *
   * @tags Recovery
   * @name checkAccountByEmail
   * @summary Check account by email
   * @request POST:/api/v1/recovery/email/check
   * @docs https://passport-identity.test.private.lifedl.net/swagger-ui/index.html#Recovery/checkAccountByEmail
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `403` `undefined` Forbidden
   * @response `423` `undefined` Locked
   * @response `500` `BaseError` Internal Server Error
   */
  checkAccountByEmail = (
    data: EmailRecoveryCheckRequest,
    params: RequestParams = {}
  ) =>
    this.request<undefined, ValidationError | undefined | BaseError>({
      path: `/api/v1/recovery/email/check`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Confirm recovery by email
   *
   * @tags Recovery
   * @name confirmRecoveryByEmail
   * @summary Confirm recovery by email
   * @request POST:/api/v1/recovery/email/confirm
   * @docs https://passport-identity.test.private.lifedl.net/swagger-ui/index.html#Recovery/confirmRecoveryByEmail
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `500` `BaseError` Internal Server Error
   */
  confirmRecoveryByEmail = (
    data: EmailConfirmRequest,
    params: RequestParams = {}
  ) =>
    this.request<undefined, ValidationError | BaseError>({
      path: `/api/v1/recovery/email/confirm`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Pre recovery by phone
   *
   * @tags Recovery
   * @name preRecoveryByPhone
   * @summary Pre recovery by phone
   * @request POST:/api/v1/recovery/phone
   * @docs https://passport-identity.test.private.lifedl.net/swagger-ui/index.html#Recovery/preRecoveryByPhone
   * @response `200` `ResendTimeResponse` OK
   * @response `400` `ValidationError` Bad request
   * @response `403` `undefined` Forbidden
   * @response `423` `undefined` Locked
   * @response `500` `BaseError` Internal Server Error
   */
  preRecoveryByPhone = (
    data: PhoneRecoveryRequest,
    params: RequestParams = {}
  ) =>
    this.request<ResendTimeResponse, ValidationError | undefined | BaseError>({
      path: `/api/v1/recovery/phone`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Check account exists by phone
   *
   * @tags Recovery
   * @name checkAccountByPhone
   * @summary Check account by phone
   * @request POST:/api/v1/recovery/phone/check
   * @docs https://passport-identity.test.private.lifedl.net/swagger-ui/index.html#Recovery/checkAccountByPhone
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `403` `undefined` Forbidden
   * @response `423` `undefined` Locked
   * @response `500` `BaseError` Internal Server Error
   */
  checkAccountByPhone = (
    data: PhoneRecoveryCheckRequest,
    params: RequestParams = {}
  ) =>
    this.request<undefined, ValidationError | undefined | BaseError>({
      path: `/api/v1/recovery/phone/check`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Confirm recovery by phone
   *
   * @tags Recovery
   * @name confirmRecoveryByPhone
   * @summary Confirm recovery by phone
   * @request POST:/api/v1/recovery/phone/confirm
   * @docs https://passport-identity.test.private.lifedl.net/swagger-ui/index.html#Recovery/confirmRecoveryByPhone
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `500` `BaseError` Internal Server Error
   */
  confirmRecoveryByPhone = (
    data: PhoneConfirmRequest,
    params: RequestParams = {}
  ) =>
    this.request<undefined, ValidationError | BaseError>({
      path: `/api/v1/recovery/phone/confirm`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Pre registration
   *
   * @tags Registration
   * @name preRegistration
   * @summary Pre registration
   * @request POST:/api/v1/registration
   * @docs https://passport-identity.test.private.lifedl.net/swagger-ui/index.html#Registration/preRegistration
   * @response `200` `ResendTimeResponse` OK
   * @response `400` `ValidationError` Bad request
   * @response `500` `BaseError` Internal Server Error
   */
  preRegistration = (data: RegistrationRequest, params: RequestParams = {}) =>
    this.request<ResendTimeResponse, ValidationError | BaseError>({
      path: `/api/v1/registration`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Confirm registration
   *
   * @tags Registration
   * @name confirmRegistration
   * @summary Confirm registration
   * @request POST:/api/v1/registration/confirm
   * @docs https://passport-identity.test.private.lifedl.net/swagger-ui/index.html#Registration/confirmRegistration
   * @response `200` `undefined` OK
   * @response `400` `ValidationError` Bad request
   * @response `500` `BaseError` Internal Server Error
   */
  confirmRegistration = (
    data: PhoneConfirmRequest,
    params: RequestParams = {}
  ) =>
    this.request<undefined, ValidationError | BaseError>({
      path: `/api/v1/registration/confirm`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Introspect access token
   *
   * @tags Token
   * @name introspect
   * @summary Introspect access token
   * @request POST:/api/v1/token/introspect
   * @docs https://passport-identity.test.private.lifedl.net/swagger-ui/index.html#Token/introspect
   * @response `200` `IntrospectTokenResponse` OK
   * @response `400` `ValidationError` Bad request
   * @response `500` `BaseError` Internal Server Error
   */
  introspect = (data: IntrospectTokenRequest, params: RequestParams = {}) =>
    this.request<IntrospectTokenResponse, ValidationError | BaseError>({
      path: `/api/v1/token/introspect`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Refresh tokens
   *
   * @tags Token
   * @name refresh
   * @summary Refresh tokens
   * @request POST:/api/v1/token/refresh
   * @docs https://passport-identity.test.private.lifedl.net/swagger-ui/index.html#Token/refresh
   * @response `200` `(CookieWrapper)[]` OK
   * @response `400` `ValidationError` Bad request
   * @response `403` `undefined` Forbidden
   * @response `423` `undefined` Locked
   * @response `500` `BaseError` Internal Server Error
   */
  refresh = (data: RenewTokenRequest, params: RequestParams = {}) =>
    this.request<CookieWrapper[], ValidationError | undefined | BaseError>({
      path: `/api/v1/token/refresh`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Revoke access account or company or only module
   *
   * @tags Token
   * @name revoke
   * @summary Revoke access
   * @request POST:/api/v1/token/revoke
   * @docs https://passport-identity.test.private.lifedl.net/swagger-ui/index.html#Token/revoke
   * @response `200` `(CookieWrapper)[]` OK
   * @response `500` `BaseError` Internal Server Error
   */
  revoke = (data: RevokeTokenRequest, params: RequestParams = {}) =>
    this.request<CookieWrapper[], BaseError>({
      path: `/api/v1/token/revoke`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
