/** @jsxImportSource @emotion/react */
import { Breakpoint, css as _, styled } from '@mui/material';
import { DetailedHTMLProps, PropsWithChildren, ThHTMLAttributes } from 'react';
import IconSVGSort from 'ui-kit/lib/icons/24/Arrow/ArrowsSort';
import { Sort, useTableContext } from '@/components/Table/useTableContext.tsx';

export type ValuesByBreakpoints = Partial<Record<Breakpoint, string>>;

const StyledTh = styled('th')<{
  w?: string | number | undefined;
  pr?: ValuesByBreakpoints;
  pl?: ValuesByBreakpoints;
}>(
  ({ w }) => _`
  padding: 4px 10px;
  min-width: ${w ? (typeof w === 'string' ? w : `${w}px`) : 'initial'};
  max-width: ${w ? (typeof w === 'string' ? w : `${w}px`) : 'initial'};
  width: ${typeof w === 'string' ? w : 'initial'};
  text-align: left;
`
);
interface ThProps<T = Record<string, string>>
  extends DetailedHTMLProps<
    ThHTMLAttributes<HTMLTableHeaderCellElement>,
    HTMLTableHeaderCellElement
  > {
  columnId?: keyof T;
  sortable?: boolean;
  className?: string;
  width?: number | string;
  pr?: ValuesByBreakpoints;
  pl?: ValuesByBreakpoints;
}

export const Th = ({
  children,
  columnId,
  sortable,
  className,
  width,
  pr,
  pl,
  ...restProps
}: PropsWithChildren<ThProps>) => {
  const ctx = useTableContext();

  const handleSort = () => {
    const sortValue =
      columnId && ctx.sortState?.[columnId as Sort] === Sort.ASC
        ? Sort.DESC
        : Sort.ASC;
    return columnId && ctx.onSort?.({ [columnId]: sortValue });
  };

  return (
    <StyledTh
      className={className}
      onClick={handleSort}
      w={width}
      pr={pr}
      pl={pl}
      {...restProps}
    >
      {sortable ? (
        <ActionsButton>
          <div>{children}</div>
          <IconSVGSort
            css={[
              columnId &&
                ctx.sortState?.[columnId as Sort] === Sort.DESC &&
                ((t) =>
                  _`path:nth-of-type(1){fill: ${t.palette.secondary.main}}`),
              columnId &&
                ctx.sortState?.[columnId as Sort] === Sort.ASC &&
                ((t) =>
                  _`path:nth-of-type(2){fill: ${t.palette.secondary.main}}`),
            ]}
          />
        </ActionsButton>
      ) : (
        children
      )}
    </StyledTh>
  );
};
const ActionsButton = styled('button')`
  display: inline-flex;
  align-items: center;
  gap: 5px;
  background-color: transparent;
  border: none;
  font-weight: inherit;
  font-family: inherit;
  color: inherit;
  cursor: pointer;
`;
