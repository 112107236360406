import React, { FC } from 'react';
import { NormalizedState } from '@/store/common/normalized.ts';
import { CompanyPermissionName, UUID } from '@/types';
import { UrlToModuleTypeMap, useParams } from '@/routes/appRoutes.ts';
import { ModuleType, Role } from '@/api/__generated__/webApi';
import { useTranslate } from '@/i18n/useTranslate.ts';
import { useEditRoleForm } from '@/store/companies/hooks/useEditRoleForm.ts';
import { useCompanyPermissionsValidator } from '@/store/permissions/useCompanyPermissionsValidator.tsx';
import { BoxHeader } from '@/components';
import { Stack, Typography } from '@mui/material';
import { RoleItem } from '@/features/module/ModulePage/fragments/RoleItem.tsx';

export const CustomRolesList: FC<{
  roles: NormalizedState<Role, string>;
  customRolesIds: UUID[] | null | undefined;
  disableEdit: boolean;
}> = ({ disableEdit, roles, customRolesIds }) => {
  const { module, moduleId, companyId } = useParams();
  const moduleType = module ? UrlToModuleTypeMap[module] : null;
  const canHaveCustomRoles = moduleType !== ModuleType.PASSPORT;
  const { t } = useTranslate('companies');
  const { showEditRoleForm } = useEditRoleForm();
  const customRolesHeadersMap: Record<ModuleType, string> = {
    [ModuleType.LIS]: t('LIS_CUSTOM_ROLES'),
    [ModuleType.PASSPORT]: t('PASSPORT_CUSTOM_ROLES'),
    [ModuleType.PROFESSIONAL]: t('HEALTHCARE_PROFESSIONAL_CUSTOM_ROLES'),
  };

  const hasPerm = useCompanyPermissionsValidator(true);

  if (!canHaveCustomRoles) {
    return null;
  }

  return (
    <>
      <BoxHeader sx={styles.header}>
        {moduleType && customRolesHeadersMap[moduleType]}
      </BoxHeader>
      {customRolesIds?.length ? (
        <Stack sx={styles.body}>
          {customRolesIds.map((id: UUID) => {
            return (
              <RoleItem
                key={id}
                role={roles.byID[id]}
                disableEdit={disableEdit}
                onEdit={
                  hasPerm(CompanyPermissionName.ModuleRenameCustomRole) ||
                  hasPerm(CompanyPermissionName.ModuleEditCustomRole)
                    ? () => {
                        showEditRoleForm({
                          roleId: id,
                          moduleId,
                          companyId,
                        });
                      }
                    : () => {}
                }
              />
            );
          })}
        </Stack>
      ) : (
        !disableEdit && (
          <Typography variant={'14_20_400'} component={'p'} color={'grey.400'}>
            {t('YOU_HAVE_NOT_ADDED_ANY_CUSTOM_ROLES_YET')}
          </Typography>
        )
      )}
    </>
  );
};

const styles = {
  header: {
    mt: {
      xs: 48,
      sm: 64,
      lg: 50,
    },
    mb: {
      xs: 48,
      sm: 48,
      lg: 24,
    },
  },
  body: {
    gap: {
      xs: 48,
      lg: 36,
    },
    mb: {
      xs: 0,
      sm: 18,
    },
  },
};
