import React, { FC, useState } from 'react';
import { NormalizedState } from '@/store/common/normalized';
import { User, UUID } from '@/types';
import { useTranslate } from '@/i18n/useTranslate';
import { Nothing, Nowrap } from '@/components';
import { Box, IconButton, Typography } from '@mui/material';
import { Table, TBody, Td, Th, THead, Tr } from '@/components/Table';
import { UserName } from '@/components/UserName';
import { getName } from '@/utils/getName';
import IconSVGCheckmark from 'ui-kit/lib/icons/24/Warning/Success';
import { CompanyAccountStatus } from '@/features/fragments/CompanyAccountStatus';
import { DotAccountStatus } from '@/features/fragments/DotAccountStatus';
import { PillButton, PillsContainer } from '@/components/Pills';
import { tableFullWidthPaddingsStyle } from '@/components/Table/tableFullWidthPaddingsStyle';
import { PhonesWithTooltip } from '@/components/PhonesDisplayWithTooltip';

const styles = {
  userNameAndStatus: { display: 'flex', gap: 12, alignItems: 'center' },
};
export interface UsersListProps {
  moduleUsers: NormalizedState<User> | null | undefined;
  companyUsers: NormalizedState<User> | null | undefined;
  removeUser: (id: UUID) => void;
  addUser: (id: UUID) => void;
  loadMoreCompanyUsers: () => void;
  loadMoreModuleUsers: () => void;
  roleUsersListLabel: string | undefined;
  showRevokeButton: (user: User | undefined) => boolean;
  showAddButton: (user: User | undefined) => boolean;
}
export const TabletList: FC<UsersListProps> = ({
  moduleUsers,
  companyUsers,
  addUser,
  removeUser,
  loadMoreCompanyUsers,
  loadMoreModuleUsers,
  roleUsersListLabel,
  showRevokeButton,
  showAddButton,
}) => {
  const { t } = useTranslate('companies');
  const [type, setType] = useState<'company' | 'module'>('company');
  const users = type === 'company' ? companyUsers : moduleUsers;
  const loadMore =
    type === 'company' ? loadMoreCompanyUsers : loadMoreModuleUsers;

  const tableHead = (
    <THead>
      <Tr>
        <Th width={'40%'} columnId={'name'}>
          {t('NAME')}
        </Th>
        <Th>{t('PHONE')}</Th>
        <Th colSpan={2}>
          <Nowrap>{t('COMPANY_STATUS')}</Nowrap>
        </Th>
      </Tr>
    </THead>
  );

  return (
    <Box>
      <Typography sx={{ typography: { sm: '18_24_500' } }} mb={24}>
        {roleUsersListLabel ?? '...'}
      </Typography>
      <PillsContainer sx={{ mb: 24 }}>
        <PillButton
          selected={type === 'module'}
          onClick={() => setType('module')}
        >
          {t('SELECTED')}
        </PillButton>
        <PillButton
          selected={type === 'company'}
          onClick={() => setType('company')}
        >
          {t('UNSELECTED')}
        </PillButton>
      </PillsContainer>
      {users?.allIDs?.length ? (
        <Box marginX={{ xs: '-24px', lg: '-36px' }}>
          <Table
            onLoad={loadMore}
            sx={[
              tableFullWidthPaddingsStyle,
              { '*': { fontSize: '14px' }, tr: { height: 56 } },
            ]}
          >
            {tableHead}
            <TBody>
              {users?.allIDs?.map((id) => {
                const user = users.byID[id] as User;
                return (
                  <Tr key={id}>
                    <Td width={200}>
                      <Box sx={styles.userNameAndStatus}>
                        <DotAccountStatus status={user.accountStatus} />
                        <UserName avatarSrc={user.avatarUrl}>
                          {getName(user)}
                        </UserName>
                      </Box>
                    </Td>
                    <Td>
                      <PhonesWithTooltip phones={user.phones} />
                    </Td>
                    <Td width={110}>
                      <CompanyAccountStatus
                        status={user.accountCompanyStatus}
                        inviteExpiredTime={user.inviteExpiredTime}
                      />
                    </Td>
                    <Td sx={{ textAlign: 'right' }}>
                      <>
                        {type === 'company' ? (
                          showAddButton(user) && (
                            <IconButton onClick={() => addUser(id)}>
                              <Box
                                component={IconSVGCheckmark}
                                sx={{
                                  color: (t) => t.palette.grey[400],
                                }}
                              />
                            </IconButton>
                          )
                        ) : (
                          <>
                            {showRevokeButton(user) && (
                              <IconButton onClick={() => removeUser(id)}>
                                <Box
                                  component={IconSVGCheckmark}
                                  sx={{
                                    color: (t) => t.palette.alert.success,
                                  }}
                                />
                              </IconButton>
                            )}
                          </>
                        )}
                      </>
                    </Td>
                  </Tr>
                );
              })}
            </TBody>
          </Table>
        </Box>
      ) : (
        <Nothing lh={38}>{t('NO_USERS')}</Nothing>
      )}
    </Box>
  );
};
