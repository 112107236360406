/** @jsxImportSource @emotion/react */
import { BoxBody, BoxHeader, WhiteBox } from '@/components/WhiteBox';
import { Link } from '@/components/Text';
import { useTranslate } from '@/i18n/useTranslate';
import React, { FC } from 'react';
import { Breadcrumbs, BreadcrumbsText } from '@/components/Breadcrumbs';
import { AppRoutes, generatePath, useParams } from '@/routes/appRoutes';
import { useRoles, useUsers } from '@/store/companies/hooks';
import { LoadingBackdrop } from '@/components/LoadingBackdrop/LoadingBackdrop';
import { Box } from '@mui/material';
import { SearchControl, useBreakpoints } from 'ui-kit';
import { CompanyHeader } from '@/features/company/fragments/CompanyHeader';
import { useSearch } from '@/hooks/useSearch';
import { TabletList } from './fragments/TabletList';
import { MobileList } from './fragments/MobileList';
import { DesktopList } from './fragments/DesktopList';
import { useSessionOwnerData } from '@/store/profile/hooks';
import { AccountCompanyStatus, ModuleType } from '@/api/__generated__/webApi';
import { CompanyPermissionName, User } from '@/types';
import { useCompanyPermissionsValidator } from '@/store/permissions/useCompanyPermissionsValidator.tsx';
import { useModuleName } from '@/features/module/ModulePage/fragments/useModuleName.tsx';

const styles = {
  headerNdSearchLayout: {
    display: 'flex',
    gap: { xs: 36, sm: 36, lg: 46 },
    justifyContent: 'space-between',
    mb: { xs: 36, lg: 64 },
    flexDirection: { xs: 'column', lg: 'row' },
  },
  listsBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: 0,
    position: 'relative',
  },
};
export const UsersList: FC = () => {
  const { companyId, moduleId, module } = useParams();
  const { role } = useRoles();
  const { t, ready } = useTranslate('companies');
  const {
    search,
    moduleUsers,
    companyUsers,
    removeUser,
    addUser,
    loadMoreCompanyUsers,
    loadMoreModuleUsers,
    fetching,
  } = useUsers();
  const b = useBreakpoints();
  const { name, moduleType } = useModuleName();
  const { account } = useSessionOwnerData();
  const isPassport = moduleType === ModuleType.PASSPORT;

  const { handleChange } = useSearch((val, type) => {
    if (val.length > 2 || !val.length) {
      search(val, type as 'company' | 'module' | undefined);
    }
  });

  const roleUserListLabel = t('ROLE_LIST_LABEL', {
    roleName: role?.name ?? '...',
  });
  const prohibitRemovalOfAUser = isPassport ? account?.id : undefined;
  const hasPermission = useCompanyPermissionsValidator(true);

  const showRevokeButton = (user: User | undefined) => {
    const userIsNotDeleted =
      user?.accountCompanyStatus !== AccountCompanyStatus.DELETED;
    const revokeIsNotProhibited = prohibitRemovalOfAUser !== user?.id;
    const sessionOwnerHasRightsToRevokeRoles = hasPermission(
      CompanyPermissionName.CompanyRevokeRole
    );
    return (
      userIsNotDeleted &&
      revokeIsNotProhibited &&
      sessionOwnerHasRightsToRevokeRoles
    );
  };

  const showAddButton = (user: User | undefined) => {
    const userIsNotDeleted =
      user?.accountCompanyStatus !== AccountCompanyStatus.DELETED;
    const sessionOwnerHasRightsToAssignRoles = hasPermission(
      CompanyPermissionName.CompanyAssignRole
    );
    return userIsNotDeleted && sessionOwnerHasRightsToAssignRoles;
  };

  if (!ready) {
    return <>...</>;
  }

  return (
    <>
      <CompanyHeader />
      <Breadcrumbs>
        <Link
          to={{
            pathname: generatePath(AppRoutes.COMPANY_MODULES, {
              companyId,
              moduleId,
              module,
            }),
            search: window.location.search,
          }}
        >
          {name}
        </Link>
        <BreadcrumbsText>{t('USERS_LIST')}</BreadcrumbsText>
      </Breadcrumbs>
      {b.xsOnly || b.smOnly ? (
        <WhiteBox>
          <Box sx={styles.headerNdSearchLayout}>
            <BoxHeader mb={0}>
              {t('MODULE_USER_LIST', { moduleName: name })}
            </BoxHeader>
            <SearchControl
              placeholder={t('SEARCH_USERS')}
              onChange={handleChange}
            />
          </Box>
          <BoxBody sx={styles.listsBody}>
            <LoadingBackdrop open={fetching} />
            {b.smOnly ? (
              <TabletList
                moduleUsers={moduleUsers}
                companyUsers={companyUsers}
                removeUser={removeUser}
                addUser={addUser}
                loadMoreCompanyUsers={loadMoreCompanyUsers}
                loadMoreModuleUsers={loadMoreModuleUsers}
                roleUsersListLabel={roleUserListLabel}
                showRevokeButton={showRevokeButton}
                showAddButton={showAddButton}
              />
            ) : (
              <MobileList
                moduleUsers={moduleUsers}
                companyUsers={companyUsers}
                removeUser={removeUser}
                addUser={addUser}
                loadMoreCompanyUsers={loadMoreCompanyUsers}
                loadMoreModuleUsers={loadMoreModuleUsers}
                roleUsersListLabel={roleUserListLabel}
                showRevokeButton={showRevokeButton}
                showAddButton={showAddButton}
              />
            )}
          </BoxBody>
        </WhiteBox>
      ) : (
        <DesktopList
          onSearchChange={handleChange}
          moduleUsers={moduleUsers}
          companyUsers={companyUsers}
          removeUser={removeUser}
          addUser={addUser}
          loadMoreCompanyUsers={loadMoreCompanyUsers}
          loadMoreModuleUsers={loadMoreModuleUsers}
          roleUsersListLabel={roleUserListLabel}
          showRevokeButton={showRevokeButton}
          showAddButton={showAddButton}
        />
      )}
    </>
  );
};
