import { call, put, select } from 'redux-saga/effects';
import { companiesApi, companyModulesApi } from '@/api';
import { companiesActions } from '../slice';
import { SagaPayload, SagaReturn } from '@/store/common/types';
import { getError } from '@/store/common/error-handlers';
import { RootState } from '../../store';
import { User } from '@/types';
import { showError500OrUnknownToast } from '@/store/common/showError500OrUnknownToast';
export const USER_LIST_PER_PAGE_MAX = 30;
export function* removeUserFromModule({
  payload,
}: SagaPayload<typeof companiesActions.requestRemoveUserFromRole>): SagaReturn {
  yield put(companiesActions.setIsFetching(true));
  const userToDelete: User | undefined = yield select(
    (root: RootState) =>
      root.companies.moduleUsersByRoles[payload.moduleId]?.[payload.roleId]
        ?.items.byID[payload.userId]
  );
  if (!userToDelete) {
    console.error(
      '[companies/saga -> removeUserFromModule] User not found',
      payload
    );
    return;
  }
  try {
    yield put(
      companiesActions.removeUser({
        userId: payload.userId,
        id: payload.moduleId,
        roleId: payload.roleId,
        type: 'module',
      })
    );
    yield put(
      companiesActions.insertUsers({
        type: 'company',
        id: payload.companyId,
        roleId: payload.roleId,
        users: [userToDelete],
      })
    );
    yield call(companyModulesApi.removeUser, {
      users: [payload.userId],
      companyId: payload.companyId,
      moduleId: payload.moduleId,
      roleId: payload.roleId,
    });
  } catch (e) {
    yield put(companiesActions.setErrors({ common: { module: getError(e) } }));
    yield put(
      companiesActions.removeUser({
        userId: payload.userId,
        id: payload.companyId,
        roleId: payload.roleId,
        type: 'company',
      })
    );
    yield put(
      companiesActions.insertUsers({
        type: 'module',
        id: payload.moduleId,
        roleId: payload.roleId,
        users: [userToDelete],
      })
    );
  } finally {
    yield put(companiesActions.setIsFetching(false));
  }
}
export function* requestAddUserToModule({
  payload,
}: SagaPayload<
  typeof companiesActions.requestAddUserToModuleRole
>): SagaReturn {
  const user: User | undefined = yield select(
    (state: RootState) =>
      state.companies.companyUsersByExclusionRoles[payload.companyId]?.[
        payload.roleId
      ]?.items.byID[payload.userId]
  );
  if (!user) {
    console.error(
      '[companies/saga -> requestAdduserToModule] User not found',
      payload
    );
    return;
  }
  try {
    yield put(companiesActions.setIsFetching(true));
    yield put(
      companiesActions.insertUsers({
        type: 'module',
        roleId: payload.roleId,
        users: [user],
        id: payload.moduleId,
      })
    );
    yield put(
      companiesActions.removeUser({
        type: 'company',
        roleId: payload.roleId,
        userId: payload.userId,
        id: payload.companyId,
      })
    );
    yield call(companyModulesApi.addUser, {
      users: [payload.userId],
      companyId: payload.companyId,
      moduleId: payload.moduleId,
      roleId: payload.roleId,
    });
  } catch (e) {
    yield put(companiesActions.setErrors({ common: { company: getError(e) } }));
    yield put(
      companiesActions.insertUsers({
        type: 'company',
        roleId: payload.roleId,
        users: [user],
        id: payload.companyId,
      })
    );
    yield put(
      companiesActions.removeUser({
        type: 'module',
        roleId: payload.roleId,
        userId: payload.userId,
        id: payload.moduleId,
      })
    );
  } finally {
    yield put(companiesActions.setIsFetching(false));
  }
}
export function* requestUsersRouter({
  payload,
}: SagaPayload<typeof companiesActions.requestRoleUsers>): SagaReturn {
  const action = companiesActions.requestRoleUsers(payload);
  // @ts-expect-error no time to explain it to ts that it is ok
  action.type = action.type + `_${payload.type}`;
  yield put(action);
}
export function* requestCompanyUsers({
  payload,
}: SagaPayload<typeof companiesActions.requestRoleUsers>): SagaReturn {
  try {
    if (!payload.silent) {
      yield put(companiesActions.setIsFetching(true));
    }
    const users: Awaited<ReturnType<typeof companiesApi.getAllUsersByPage>> =
      yield call(companiesApi.getAllUsersByPage, {
        companyId: payload.companyId,
        filter: payload.filter,
        startPage: payload.pageIndex,
        includeRoles: payload.includeRoles,
        excludeRoleId: payload.excludeRoleId,
        includeRoleId: payload.includeRoleId,
        perPage: USER_LIST_PER_PAGE_MAX,
      });
    yield put(
      companiesActions.setUsers({
        type: payload.type,
        id: payload.companyId,
        roleId: payload.roleId,
        users: users.items,
        hasMore: users.hasNext,
        replace: payload.replace,
      })
    );
  } catch (e) {
    showError500OrUnknownToast(e);
    const error = getError(e);
    yield put(companiesActions.setError({ common: error }));
  } finally {
    if (!payload.silent) {
      yield put(companiesActions.setIsFetching(false));
    }
  }
}

export function* requestModuleUsers({
  payload,
}: SagaPayload<typeof companiesActions.requestRoleUsers>): SagaReturn {
  try {
    if (!payload.silent) {
      yield put(companiesActions.setIsFetching(true));
    }
    const users: Awaited<ReturnType<typeof companyModulesApi.getUsersByPage>> =
      yield call(companyModulesApi.getUsersByPage, {
        companyId: payload.companyId,
        moduleId: payload.moduleId as string,
        roleId: payload.roleId,
        filter: payload.filter,
        startPage: payload.pageIndex,
        perPage: USER_LIST_PER_PAGE_MAX,
      });
    if (!users.items.length && payload.pageIndex !== 0) {
      yield call(requestModuleUsers, {
        payload: { ...payload, pageIndex: payload.pageIndex - 1 },
      });
      return;
    }
    yield put(
      companiesActions.setUsers({
        type: payload.type,
        id: payload.moduleId as string,
        roleId: payload.roleId,
        users: users.items,
        hasMore: users.hasNext,
        replace: payload.replace,
      })
    );
  } catch (e) {
    showError500OrUnknownToast(e);
    const error = getError(e);
    yield put(companiesActions.setError({ common: error }));
  } finally {
    if (!payload.silent) {
      yield put(companiesActions.setIsFetching(false));
    }
  }
}
