/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { Tooltip } from 'ui-kit';
import { Box, css as _, styled, SxProps, Theme } from '@mui/material';
import { alpha } from '@/theme/utils';
import { spreadSx } from '@/utils/spreadSx';
import { StatusColor } from './StatusColor.tsx';

const colorMap: Record<StatusColor, (t: Theme) => string> = {
  [StatusColor.Error]: (t) => t.palette.alert.error,
  [StatusColor.Warning]: (t) => t.palette.alert.warning,
  [StatusColor.Success]: (t) => t.palette.alert.success,
  [StatusColor.Info]: (t) => t.palette.alert.info,
  [StatusColor.Progress]: (t) => t.palette.alert.progress,
  [StatusColor.Achieved]: (t) => t.palette.alert.achieved,
  [StatusColor.Blocked]: (t) => t.palette.alert.blocked,
};
const StyledStatus = styled('div')<{ color?: StatusColor }>(
  (p) => _`
  --size: 6px;
  width: var(--size);
  height: var(--size);
  min-width: var(--size);
  border-radius: 50%;
  background-color: ${p.color && colorMap[p.color]?.(p.theme)};
`
);
const styles = {
  dot: {
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: -9,
    marginRight: -9,
    cursor: 'pointer',
    flexShrink: 0,
  },
};
export const DotStatus: FC<{
  color?: StatusColor;
  title?: string;
  className?: string;
  sx?: SxProps<Theme>;
}> = ({ color, title, className, sx }) => {
  return (
    <Tooltip placement={'bottom-start'} title={title ?? ''}>
      <Box sx={[styles.dot, ...spreadSx(sx)]} className={className}>
        <StyledStatus color={color} />
      </Box>
    </Tooltip>
  );
};

export const StatusText = styled('div')<{ color: StatusColor }>(
  (p) => _`
  background-color: ${alpha(colorMap[p.color](p.theme), 10)};
  color: ${colorMap[p.color](p.theme)};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  height: 24px;
  white-space: nowrap;
  padding: 0 8px;
  display: inline-flex;
  align-items: center;
  &:hover {
    background-color: ${alpha(colorMap[p.color](p.theme), 20)};
  }
`
);
