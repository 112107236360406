/** @jsxImportSource @emotion/react */
import { Box, Collapse, css as _, Stack, styled } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { FC, Fragment, useEffect, useLayoutEffect, useState } from 'react';
import { SideMenuItem } from './SidebarContainer';
import { Tooltip } from 'ui-kit';
import { useParams } from '@/routes/appRoutes';
import { shouldNotForwardProps } from '@/utils/shouldNotForwardProps';

export const SidebarMenuStripe: FC<{
  menuItems: SideMenuItem[];
  className?: string;
}> = ({ menuItems, className }) => {
  const [opacity, setOpacity] = useState(0);
  useEffect(() => {
    setOpacity(1);
  }, []);

  const [collapseState, setCollapseState] = useState<Record<string, boolean>>(
    {}
  );
  const { companyId } = useParams();

  useLayoutEffect(() => {
    if (companyId) {
      const index = menuItems.findIndex(({ id }) => id === companyId);
      if (index) {
        setCollapseState(() => {
          return {
            [index]: true,
          };
        });
      }
    } else {
      setCollapseState({});
    }
  }, [companyId, menuItems]);

  return (
    <Stack
      css={_`opacity: ${opacity}; transition: all .1s; transition-delay: .2s`}
      className={className}
    >
      {menuItems.map((m, i) => {
        return (
          <Fragment key={i}>
            <Tooltip
              placement={'right'}
              title={m.title}
              sx={(t) => ({
                background: t.palette.secondary.main,
                color: 'white',
              })}
              arrowSx={(t) => ({
                color: t.palette.secondary.main,
              })}
            >
              <MenuIconButton
                to={{
                  pathname: m.link?.pathname,
                  search: m.link?.search ?? window.location.search,
                }}
                end={m.link?.exact}
                className={m.selected ? 'active' : ''}
              >
                {m.icon}
              </MenuIconButton>
            </Tooltip>
            <Collapse
              in={m.collapsible ? collapseState[i] : true}
              timeout={'auto'}
              unmountOnExit
            >
              {m.items?.map((d, i) => {
                return (
                  <Fragment key={i}>
                    <Tooltip
                      title={d.title}
                      placement={'right'}
                      key={i}
                      sx={(t) => ({
                        background: t.palette.secondary.main,
                        color: 'white',
                      })}
                      arrowSx={(t) => ({
                        color: t.palette.secondary.main,
                      })}
                    >
                      <MenuIconButton
                        disabled={d.disabled}
                        to={{
                          pathname: d.link?.pathname,
                          search: d.link?.search ?? window.location.search,
                        }}
                      >
                        {d.icon}
                      </MenuIconButton>
                    </Tooltip>
                  </Fragment>
                );
              })}
              <Box height={30} />
            </Collapse>
          </Fragment>
        );
      })}
    </Stack>
  );
};
const MenuIconButton = styled(
  NavLink,
  shouldNotForwardProps(['disabled'])
)<{ disabled?: boolean }>(({ theme: t, disabled }) => {
  return _`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    &.active, &:active {
      background: ${t.palette.secondary.dark} !important;
    }
    &:hover {
      background: ${t.palette.secondary.light};
    }
    pointer-events: ${disabled ? 'none' : ''};
    path {
      fill: ${disabled ? t.palette.grey['300'] : 'white'} !important;
    }
  `;
});
