import React, { FC } from 'react';
import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';

import IconCheckMarkDisabled from 'ui-kit/lib/icons/24/Warning/Success';
import { useBreakpoints } from 'ui-kit';

const none = {};
export const ListItem: FC<{
  disabled: boolean;
  label: string;
  logoSrc: string;
  onClick: () => void;
}> = ({ label, disabled, onClick, logoSrc }) => {
  const { lg } = useBreakpoints();

  if (lg) {
    return (
      <Stack
        onClick={disabled ? undefined : onClick}
        direction={'row'}
        sx={[styles.container, disabled ? styles.disabledContainer : none]}
      >
        <Stack direction={'row'} sx={[styles.iconContainer]}>
          <IconCheckMarkDisabled />
        </Stack>
        <Stack sx={{ justifyContent: 'center' }}>
          <Stack sx={styles.logoContainer}>
            <Box
              component="img"
              sx={[
                styles.logoImage,
                disabled ? styles.disabledLogoImage : none,
              ]}
              src={logoSrc}
            />
          </Stack>
        </Stack>
        <Stack sx={styles.delimiter}>
          <Stack sx={styles.delimiterLine} />
        </Stack>
        <Stack justifyContent={'center'}>
          <Typography
            variant={'14_18_500'}
            sx={disabled ? styles.disabledLabel : none}
          >
            {label}
          </Typography>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      onClick={disabled ? undefined : onClick}
      direction={'row'}
      sx={[styles.container, disabled ? styles.disabledContainer : none]}
    >
      <Box>
        <IconCheckMarkDisabled />
      </Box>
      <Stack sx={{ gap: 6 }}>
        <Typography
          variant={'14_18_500'}
          sx={disabled ? styles.disabledLabel : none}
        >
          {label}
        </Typography>
        <Box>
          <Box
            component="img"
            sx={[
              styles.logoImageXsToMd,
              disabled ? styles.disabledLogoImage : none,
            ]}
            src={logoSrc}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

const styles = {
  container: {
    minHeight: { xs: 64, lg: 52 },
    gap: { xs: 7, sm: 12, lg: 24 },
    paddingLeft: { xs: 24, lg: 36 },
    paddingRight: { xs: 12, lg: 36 },
    paddingY: 10,
    boxSizing: 'border-box',
    backgroundColor: 'common.white',
    cursor: 'pointer',
    '&:first-of-type': {
      border: 'none!important',
    },
  },
  disabledContainer: {
    backgroundColor: (t) => t.palette.table.base.disabled,
    cursor: '',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    color: (t) => t.palette.togglebtn.unselected.default,
  },
  logoContainer: { height: 30, width: 80, overflow: 'hidden' },
  disabledLogoImage: { filter: 'grayscale(100%)', opacity: 0.2 },
  logoImage: { height: 30, width: 'max-content' },
  logoImageXsToMd: { height: 16, width: 'auto', display: 'block' },
  delimiter: { width: 17, flexDirection: 'row', justifyContent: 'center' },
  delimiterLine: {
    width: '1px',
    backgroundColor: 'grey.300',
  },
  disabledLabel: { color: (t) => t.palette.content.base.disabled },
} satisfies Record<string, SxProps<Theme>>;
