import { useTranslate } from '@/i18n/useTranslate';
import { Button, useBreakpoints } from 'ui-kit';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormikRedux,
} from '@/components';
import { Stack, Theme, Typography } from '@mui/material';
import { Img } from '@/features/profile/Security/Authentication/Img';
import React, { useMemo, useState } from 'react';
import TrashCanIcon from 'ui-kit/lib/icons/24/Action/Delete';
import { useQrActions, useQrData } from '@/store/profile-qr/hooks';
import { TextAreaControlF } from '@/components/FormikRedux/TextAreaControlF';
import { toDataUri } from '@/utils/toDataUrl';
import { useValidationSchema } from './useValidationSchema.tsx';

export const EditQrDialog = () => {
  const d = useQrData();
  const a = useQrActions();
  return (
    <Dialog
      fullFrameMobile
      open={d.step === 'edit'}
      size={'sm'}
      onClose={a.cancelEdit}
    >
      <DialogLogic />
    </Dialog>
  );
};

export const DialogLogic = () => {
  const { t } = useTranslate('profile');
  const { xsOnly } = useBreakpoints();
  const [deleteQr, setDeleteQr] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const schema = useValidationSchema();
  const d = useQrData();
  const a = useQrActions();
  const initValue = useMemo(() => {
    return { description: d.selectedQr?.description ?? '' };
    // ignored description changes
    // eslint-disable-next-line
  }, []);

  const handleDelete = async () => {
    setIsDeleting(true);
    await a.deleteQr();
    setIsDeleting(false);
  };
  return (
    <FormikRedux
      initialValues={initValue}
      onSubmit={a.submitEditQr}
      serverErrors={d.errors}
      resetServerErrors={a.resetErrors}
      validationSchema={schema}
      noForm
    >
      {(f) => {
        return (
          <>
            <DialogTitle onClose={a.cancelEdit}>
              {t('SHOW_QR_CODE')}
            </DialogTitle>
            <DialogContent sx={styles.dialogContent}>
              <Stack sx={styles.qrLayout}>
                <Stack sx={styles.qr}>
                  <Img src={toDataUri(d.selectedQr?.qrCode)} />
                </Stack>
              </Stack>
              <TextAreaControlF
                minRows={3}
                name={'description'}
                label={t('DESCRIPTION')}
                max={255}
              />
            </DialogContent>
            <DialogActions>
              {xsOnly ? (
                <>
                  <Button
                    disabled={f.isSubmitting}
                    variant={'outlined'}
                    onClick={() => setDeleteQr(true)}
                  >
                    {t('DELETE')}
                  </Button>
                  <Button
                    color={'secondary'}
                    disabled={f.isSubmitting}
                    onClick={() => f.handleSubmit()}
                  >
                    {t('SAVE')}
                  </Button>
                </>
              ) : (
                <Stack
                  direction={'row'}
                  gap={24}
                  sx={{
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Button
                    variant={'outlined'}
                    startIcon={<TrashCanIcon />}
                    onClick={() => setDeleteQr(true)}
                    disabled={f.isSubmitting}
                  >
                    {t('DELETE')}
                  </Button>
                  <Button
                    color={'secondary'}
                    disabled={f.isSubmitting}
                    onClick={() => f.handleSubmit()}
                  >
                    {t('SAVE')}
                  </Button>
                </Stack>
              )}
            </DialogActions>
            {deleteQr && (
              <Stack sx={styles.deleteContentLayout}>
                <DialogTitle onClose={a.cancelEdit}>
                  {t('DELETE_QR_CODE')}
                </DialogTitle>
                <Stack
                  sx={{
                    padding: { xs: '36px 16px', sm: '36px 24px' },
                    flex: '1 1 auto',
                  }}
                >
                  <Typography sx={{ typography: { xs: '14_18_500' } }}>
                    {t('ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_CONNECTION_TO_')}
                  </Typography>
                </Stack>
                <DialogActions spaceBetween>
                  <Button
                    variant={'outlined'}
                    color={'secondary'}
                    disabled={isDeleting}
                    onClick={() => {
                      setDeleteQr(false);
                    }}
                  >
                    {t('NO')}
                  </Button>
                  <Button
                    color={'secondary'}
                    disabled={isDeleting}
                    onClick={() => {
                      void handleDelete();
                    }}
                  >
                    {t('YES')}
                  </Button>
                </DialogActions>
              </Stack>
            )}
          </>
        );
      }}
    </FormikRedux>
  );
};

const styles = {
  deleteContentLayout: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: (t: Theme) => t.palette.common.white,
    textAlign: 'left',
  },
  qrLayout: {
    alignItems: 'center',
    mb: { xs: 24, sm: 36 },
  },
  qr: {
    maxWidth: {
      xs: 280,
      sm: 360,
    },
  },
  dialogContent: { paddingTop: { xs: 24, sm: 36 }, position: 'relative' },
};
