import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createNormalizedState,
  deleteByIdFromNormalizedState,
  NormalizedState,
  updateNormalizedState,
} from '@/store/common/normalized';
import { set } from '@/store/common/utils';
import { globalStateResetAction } from '@/store/common/actions';
import {
  CompanyUUID,
  ModuleUUID,
  OpenIdSettings,
  PartialRecord,
  RoleUUID,
  TimestampMs,
  User,
  UUID,
} from '@/types';
import { AppRoutes, UrlParams } from '@/routes/appRoutes';
import { current } from 'immer';
import {
  Company,
  CompanyAddress,
  CompanyAddressCreateRequest,
  CompanyAddressUpdateRequest,
  CompanyData,
  Module,
  ModuleType,
  NPI1Data,
  NPI2Data,
  Permission,
  Role,
} from '@/api/__generated__/webApi';
import { ValidationErrorType } from '@/types/ValidationError';
import { NEW_ROLE_ID_STUB } from '@/features/module/constants.ts';
import { withTimestamp } from '@/utils/withTimestamp.ts';

export interface SearchUsersAction {
  moduleId?: UUID;
  companyId: UUID;
  type: 'company' | 'module';
  roleId: UUID;
  filter: string;
  pageIndex: number;
  replace: boolean;
  silent?: boolean;
  includeRoles?: boolean;
  includeRoleId?: string;
  excludeRoleId?: string;
}

export type FormState = 'active' | 'done' | 'failed' | 'sending' | null;

export interface FormModel {
  errors?: PartialRecord<string, ValidationErrorType> | null;
  state?: FormState;
}
export interface EditRoleForm extends FormModel {
  roleId?: UUID;
  moduleId?: UUID;
  companyId?: UUID;
}
export interface CompanyLogoForm extends FormModel {
  companyId?: string;
}

type ErrorsRecord = Partial<
  Record<
    'module' | 'company' | 'permissions' | 'allPermissions' | 'enterModule',
    ValidationErrorType | null
  >
>;
export type LocalCompanyData = Omit<Company, 'modules'>;
export enum FormNames {
  CreateAddress = 'createAddress',
  EditAddress = 'editAddress',
  AdConnection = 'activeDirectoryConnection',
  UploadAvatar = 'uploadAvatar',
  NewRole = 'newRole',
  EditRole = 'editRole',
}

export interface CompaniesState {
  fetching: boolean;
  error: ValidationErrorType | null;
  errors: ErrorsRecord;
  companyDetails: PartialRecord<UUID, NPI1Data | NPI2Data | CompanyData>;
  staleTime: Record<UUID, TimestampMs>;
  companies: NormalizedState<LocalCompanyData>;
  openIdSettings: NormalizedState<OpenIdSettings, CompanyUUID>;
  modules: PartialRecord<CompanyUUID, NormalizedState<Module>>;
  roles: PartialRecord<ModuleUUID, NormalizedState<Role>>;
  rolePermissions: PartialRecord<
    ModuleUUID,
    PartialRecord<RoleUUID, NormalizedState<Permission>>
  >;
  modulePermissions: PartialRecord<ModuleUUID, NormalizedState<Permission>>;
  moduleUsersByRoles: PartialRecord<
    ModuleUUID,
    PartialRecord<
      RoleUUID,
      {
        hasMore: boolean;
        items: NormalizedState<User>;
      }
    >
  >;
  companyUsersByExclusionRoles: PartialRecord<
    CompanyUUID,
    PartialRecord<
      RoleUUID,
      {
        hasMore: boolean;
        items: NormalizedState<User>;
      }
    >
  >;
  redirect: {
    pattern: (typeof AppRoutes)[keyof typeof AppRoutes];
    params: Partial<UrlParams>;
  } | null;
  createModule: {
    fetching: boolean;
    dialogOpen: boolean;
    notificationLisOpen: boolean;
  };
  forms: Partial<{
    [FormNames.CreateAddress]: FormModel;
    [FormNames.AdConnection]: FormModel;
    [FormNames.EditAddress]: FormModel;
    [FormNames.UploadAvatar]: CompanyLogoForm;
    [FormNames.NewRole]: FormModel;
    [FormNames.EditRole]: EditRoleForm;
  }>;
}

const initialState: CompaniesState = {
  fetching: true,
  error: null,
  errors: {
    company: null,
    module: null,
  },
  companies: createNormalizedState([]),
  openIdSettings: createNormalizedState([]),
  companyDetails: {},
  staleTime: {},
  roles: {},
  modules: {},
  rolePermissions: {},
  modulePermissions: {},
  moduleUsersByRoles: {},
  companyUsersByExclusionRoles: {},
  redirect: null,
  forms: {},
  createModule: {
    fetching: false,
    dialogOpen: false,
    notificationLisOpen: false,
  },
};
const slice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    requestModuleStatus(
      _,
      _action: PayloadAction<{ companyId: UUID; moduleId: UUID }>
    ) {},
    requestUploadCompanyLogo(
      _,
      _action: PayloadAction<{ companyId: UUID; data: FormData }>
    ) {},
    requestCompanies() {},
    requestCreateModule(
      _state,
      _action: PayloadAction<{ companyId: UUID; moduleType: ModuleType }>
    ) {},
    requestCompanyDetails(
      _state,
      _action: PayloadAction<{
        companyId: UUID;
        quiet?: boolean;
        forced?: boolean;
      }>
    ) {},
    requestEditRole(
      _state,
      _action: PayloadAction<{ role: Role; companyId: UUID; moduleId: UUID }>
    ) {},
    requestRoles(
      _state,
      _action: PayloadAction<{
        companyId: UUID;
        moduleId: UUID;
        replace?: boolean;
      }>
    ) {},
    requestRolePermissions(
      _state,
      _action: PayloadAction<{ companyId: UUID; moduleId: UUID; roleId: UUID }>
    ) {},
    requestModulePermissions(
      _state,
      _action: PayloadAction<{ companyId: UUID; moduleId: UUID }>
    ) {},
    requestAddRolePermissions(
      _state,
      _action: PayloadAction<{
        companyId: UUID;
        moduleId: UUID;
        roleId: UUID | typeof NEW_ROLE_ID_STUB;
        ids: UUID[];
      }>
    ) {},
    requestRemoveRolePermissions(
      _state,
      _action: PayloadAction<{
        companyId: UUID;
        moduleId: UUID;
        roleId: UUID;
        ids: UUID[];
      }>
    ) {},
    requestEnterModule(
      _state,
      _action: PayloadAction<{
        moduleType: ModuleType;
        companyId: UUID;
        redirectUrl?: string;
        openInNewTab?: boolean;
      }>
    ) {},
    requestCreateADConnection(
      _state,
      _action: PayloadAction<{ companyId: UUID; openIdData: OpenIdSettings }>
    ) {},
    requestAuthorizeWithAd(
      _state,
      _action: PayloadAction<{ companyId: UUID; currentUrl: string }>
    ) {},
    setCompanyLock(
      state,
      { payload }: PayloadAction<{ companyId: UUID; locked: boolean }>
    ) {
      const company = state.companies?.byID?.[payload.companyId];
      if (company) {
        company.locked = payload.locked;
      }
    },
    setCompanyDescription(
      state,
      {
        payload,
      }: PayloadAction<{ companyId: UUID; description: string | undefined }>
    ) {
      const company = state.companies?.byID?.[payload.companyId];
      if (company) {
        company.description = payload.description;
      } else {
        console.error('[setCompanyDescription] Company not found');
      }
    },
    setOpenIdSettings(
      state,
      { payload }: PayloadAction<{ companyId: UUID; settings: OpenIdSettings }>
    ) {
      state.openIdSettings.byID[payload.companyId] = payload.settings;
      state.openIdSettings.allIDs.push(payload.companyId);
    },
    setModules(state, action: PayloadAction<Record<CompanyUUID, Module[]>>) {
      for (const [companyId, newModules] of Object.entries(action.payload)) {
        if (!state.modules?.[companyId]) {
          state.modules[companyId] = createNormalizedState([]);
        }
        updateNormalizedState(
          state.modules[companyId] as NonNullable<
            NormalizedState<Module, string>
          >,
          newModules
        );
      }
    },
    completeCreateModule(
      state,
      { payload }: PayloadAction<{ moduleType: ModuleType }>
    ) {
      state.createModule.dialogOpen = false;
      if (payload.moduleType === ModuleType.LIS) {
        state.createModule.notificationLisOpen = true;
      }
    },
    setCompanies(
      state,
      action: PayloadAction<{
        companies: LocalCompanyData[] | null;
        replace?: boolean;
      }>
    ) {
      if (action.payload.companies == null) {
        state.companies = createNormalizedState([]);
        return;
      }

      if (action.payload.replace || state.companies == null) {
        state.companies = createNormalizedState(action.payload.companies ?? []);
      } else {
        updateNormalizedState(state.companies, action.payload.companies ?? []);
      }
    },
    setRoles(
      state,
      {
        payload,
      }: PayloadAction<{
        moduleId: UUID;
        roles: Role[] | null;
        replace?: boolean;
      }>
    ) {
      if (payload.roles == null) {
        delete state.roles?.[payload.moduleId];
        return;
      }
      const stateKey: keyof typeof state = 'roles';
      set(state, [stateKey, payload.moduleId], createNormalizedState([]), true);
      if (!state.roles?.[payload.moduleId]) {
        return;
      }
      if (payload.replace) {
        state.roles[payload.moduleId] = createNormalizedState(
          payload.roles ?? []
        );
      } else {
        updateNormalizedState(
          state.roles[payload.moduleId] as NonNullable<
            NormalizedState<Role, string>
          >,
          payload.roles ?? []
        );
      }
    },
    deleteRoles(
      state,
      { payload }: PayloadAction<{ ids: UUID[]; moduleId: UUID }>
    ) {
      if (state.roles[payload.moduleId]) {
        deleteByIdFromNormalizedState(
          state.roles[payload.moduleId] as NonNullable<
            NormalizedState<Role, string>
          >,
          payload.ids
        );
        for (const roleId of payload.ids) {
          delete state.rolePermissions?.[payload.moduleId]?.[roleId];
        }
      }
    },
    transformRoleToNew(
      state,
      { payload }: PayloadAction<{ roleId: UUID; moduleId: UUID }>
    ) {
      const roles = state.roles[payload.moduleId];
      if (roles) {
        const role = {
          ...current(roles.byID[payload.roleId]),
          id: NEW_ROLE_ID_STUB,
        };
        deleteByIdFromNormalizedState(
          roles as NonNullable<NormalizedState<Role, string>>,
          [payload.roleId]
        );
        updateNormalizedState(roles, [role]);
      }
    },
    deletePermissions(
      state,
      { payload }: PayloadAction<{ moduleId: UUID; roleId: UUID; ids?: UUID[] }>
    ) {
      if (!payload.ids) {
        delete state.rolePermissions?.[payload.moduleId]?.[payload.roleId];
        return;
      }
      const rolePermissions =
        state.rolePermissions[payload.moduleId]?.[payload.roleId];
      if (rolePermissions) {
        deleteByIdFromNormalizedState(rolePermissions, payload.ids);
      }
    },
    setRolePermissions(
      state,
      {
        payload,
      }: PayloadAction<{
        roleId: UUID;
        moduleId: UUID;
        permissions: Permission[] | null;
        replace?: boolean;
      }>
    ) {
      if (payload.permissions == null) {
        delete state.rolePermissions?.[payload.moduleId]?.[payload.roleId];
        return;
      }

      const stateKey: keyof typeof state = 'rolePermissions';
      set(
        state,
        [stateKey, payload.moduleId, payload.roleId],
        createNormalizedState([]),
        true
      );
      if (!state.rolePermissions?.[payload.moduleId]?.[payload.roleId]) {
        (state.rolePermissions[payload.moduleId] =
          state.rolePermissions[payload.moduleId] ?? {})[payload.roleId] =
          createNormalizedState([]);
      }
      if (payload.replace) {
        (state.rolePermissions[payload.moduleId] =
          state.rolePermissions[payload.moduleId] ?? {})[payload.roleId] =
          createNormalizedState(payload.permissions ?? []);
      } else {
        updateNormalizedState(
          state.rolePermissions[payload.moduleId]?.[
            payload.roleId
          ] as NormalizedState<Permission>,
          payload.permissions ?? []
        );
      }
    },
    setModulePermissions(
      state,
      {
        payload,
      }: PayloadAction<{
        moduleId: UUID;
        permissions: Permission[] | null;
        replace?: boolean;
      }>
    ) {
      if (payload.permissions == null) {
        delete state.modulePermissions?.[payload.moduleId];
        return;
      }

      const stateKey: keyof typeof state = 'modulePermissions';
      set(state, [stateKey, payload.moduleId], createNormalizedState([]), true);

      if (!state.modulePermissions[payload.moduleId]) {
        state.modulePermissions[payload.moduleId] = createNormalizedState([]);
      }
      if (payload.replace) {
        state.modulePermissions[payload.moduleId] = createNormalizedState(
          payload.permissions ?? []
        );
      } else {
        updateNormalizedState(
          state.modulePermissions[
            payload.moduleId
          ] as NormalizedState<Permission>,
          payload.permissions ?? []
        );
      }
    },
    setCompanyDetails(
      state,
      {
        payload,
      }: PayloadAction<{
        companyId: UUID;
        details: NPI1Data | NPI2Data | CompanyData;
      }>
    ) {
      if (!state.companyDetails) {
        state.companyDetails = {};
      }
      state.companyDetails[payload.companyId] = payload.details;
    },
    requestRoleUsers(_state, _action: PayloadAction<SearchUsersAction>) {},
    requestRemoveUserFromRole(
      _state,
      _action: PayloadAction<{
        userId: UUID;
        companyId: UUID;
        moduleId: UUID;
        roleId: UUID;
      }>
    ) {},
    requestAddUserToModuleRole(
      _state,
      _action: PayloadAction<{
        userId: UUID;
        companyId: UUID;
        moduleId: UUID;
        roleId: UUID;
      }>
    ) {},
    requestOpenIdSettings(
      _state,
      _action: PayloadAction<{ companyId: UUID }>
    ) {},
    removeUser(
      state,
      {
        payload,
      }: PayloadAction<{
        userId: UUID;
        id: CompanyUUID | ModuleUUID;
        roleId: UUID;
        type: 'company' | 'module';
      }>
    ) {
      const users =
        payload.type === 'module'
          ? state.moduleUsersByRoles?.[payload.id]?.[payload.roleId]?.items
          : state.companyUsersByExclusionRoles?.[payload.id]?.[payload.roleId]
              ?.items;

      if (users) {
        deleteByIdFromNormalizedState(users, [payload.userId]);
      } else {
        console.error(
          '[companies/slice -> #removeUser] Unable to find users with given params',
          { payload }
        );
      }
    },
    setUsers(
      state,
      {
        payload,
      }: PayloadAction<{
        type: 'company' | 'module';
        id: ModuleUUID | CompanyUUID;
        roleId: UUID;
        users: User[];
        hasMore: boolean;
        replace: boolean;
      }>
    ) {
      const usersState =
        payload.type === 'module'
          ? state.moduleUsersByRoles
          : state.companyUsersByExclusionRoles;
      const users = usersState[payload.id]?.[payload.roleId];
      if (users?.items && !payload.replace) {
        users.hasMore = payload.hasMore;
        updateNormalizedState(users.items, payload.users);
      } else {
        (usersState[payload.id] = usersState[payload.id] ?? {})[
          payload.roleId
        ] = {
          hasMore: payload.hasMore,
          items: createNormalizedState(payload.users ?? []),
        };
      }
    },
    insertUsers(
      state,
      {
        payload,
      }: PayloadAction<{
        type: 'company' | 'module';
        id: ModuleUUID | CompanyUUID;
        roleId: UUID;
        users: User[];
      }>
    ) {
      const usersState =
        payload.type === 'module'
          ? state.moduleUsersByRoles
          : state.companyUsersByExclusionRoles;
      (usersState[payload.id] = usersState[payload.id] ?? {})[payload.roleId] =
        usersState[payload.id]?.[payload.roleId] ?? {
          hasMore: false,
          items: createNormalizedState(payload.users ?? []),
        };
      if (usersState?.[payload.id]?.[payload.roleId]?.items) {
        updateNormalizedState(
          usersState?.[payload.id]?.[payload.roleId]
            ?.items as NormalizedState<User>,
          payload.users
        );
      } else {
        console.error(
          '[companies/slice -> #insertUsers] Claimed user state does not exist',
          payload
        );
      }
    },
    /// addresses
    requestCreateAddress(
      _state,
      _action: PayloadAction<{
        companyId: UUID;
        address: CompanyAddressCreateRequest;
      }>
    ) {},
    requestEditAddress(
      _state,
      _action: PayloadAction<{
        companyId: UUID;
        addressId: UUID;
        partialAddress: CompanyAddressUpdateRequest;
      }>
    ) {},
    updateCompanyAddress(
      state,
      { payload }: PayloadAction<{ companyId: string; address: CompanyAddress }>
    ) {
      const details = state.companyDetails[payload.companyId];
      if (!details?.practiceAddresses) {
        console.error(
          '[updatedCompanyAddress], Error. practice addresses are undefined'
        );
        return;
      }
      const index = details.practiceAddresses.findIndex(
        (a: CompanyAddress) => a.id === payload.address.id
      );
      const insertionIndex =
        index === -1 ? details.practiceAddresses?.length - 1 : index;
      const deleteCount = index === -1 ? 0 : 1;
      details?.practiceAddresses?.splice(
        insertionIndex,
        deleteCount,
        payload.address
      );
    },
    /// common
    setFormErrors(
      state,
      {
        payload,
      }: PayloadAction<{
        formName: FormNames;
        errors: PartialRecord<string, ValidationErrorType>;
      }>
    ) {
      (state.forms[payload.formName] =
        state.forms[payload.formName] ?? {}).errors = payload.errors;
    },
    resetFormErrors(
      state,
      { payload }: PayloadAction<{ formName: FormNames }>
    ) {
      (state.forms[payload.formName] =
        state.forms[payload.formName] ?? {}).errors = null;
    },
    resetForm(state, { payload }: PayloadAction<{ formName: FormNames }>) {
      delete state.forms[payload.formName];
    },
    setFormState(
      state,
      { payload }: PayloadAction<{ formName: FormNames; formState: FormState }>
    ) {
      (state.forms[payload.formName] =
        state.forms[payload.formName] ?? {}).state = payload.formState;
    },
    setStaleTime(
      state,
      action: PayloadAction<{ uuid: UUID; time: TimestampMs }>
    ) {
      state.staleTime[action.payload.uuid] = action.payload.time;
    },
    setRedirect(
      state,
      {
        payload,
      }: PayloadAction<{
        pattern: (typeof AppRoutes)[keyof typeof AppRoutes];
        params: Partial<UrlParams>;
      } | null>
    ) {
      state.redirect = payload;
    },
    setError(
      state,
      action: PayloadAction<{ common: ValidationErrorType } | null>
    ) {
      state.error = action.payload?.common ?? null;
    },
    setErrors(state, action: PayloadAction<{ common: ErrorsRecord }>) {
      state.errors = action.payload.common;
    },
    resetErrors(state) {
      state.error = null;
      state.errors = {};
    },
    setIsFetching(state, action: PayloadAction<boolean>) {
      state.fetching = action.payload;
    },
    refreshCompanyLogoUrl(
      state,
      { payload }: PayloadAction<{ companyId: UUID }>
    ) {
      const company = state.companies.byID[payload.companyId];
      if (company) {
        company.logoUrl = withTimestamp(company.logoUrl);
      }
    },
    setForm(
      state,
      {
        payload,
      }: PayloadAction<{ forms: CompaniesState['forms']; merge: boolean }>
    ) {
      if (!payload.forms) {
        state.forms = {};
        return;
      }
      for (const formName of Object.keys(payload.forms)) {
        if (state.forms?.[formName as FormNames] && payload?.merge) {
          const newState = {
            ...state.forms[formName as FormNames],
            ...payload.forms[formName as FormNames],
          };
          state.forms[formName as FormNames] = newState;
        } else {
          (state.forms = state.forms ?? {})[formName as FormNames] =
            payload.forms[formName as FormNames];
        }
      }
    },
    dialogCreateModuleSetOpen(
      state,
      { payload }: PayloadAction<{ open: boolean }>
    ) {
      state.createModule.dialogOpen = payload.open;
    },
    dialogCreateModuleSetFetching(
      state,
      { payload }: PayloadAction<{ fetching: boolean }>
    ) {
      state.createModule.fetching = payload.fetching;
    },
    dialogCreateModuleLisNotificationSetOpen(
      state,
      { payload }: PayloadAction<{ open: boolean }>
    ) {
      state.createModule.notificationLisOpen = payload.open;
    },
  },
  extraReducers(builder) {
    builder.addCase(globalStateResetAction, () => {
      return initialState;
    });
  },
});

export const companiesStateName = slice.name;
export const companiesReducer = slice.reducer;
export const companiesActions = slice.actions;
