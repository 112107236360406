import { AddressInfo } from 'ui-kit/lib/address';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { TKeys, useTranslate } from '@/i18n';
import { AddressLookup } from '@/components/Google/AddressLookup';
import { AddAddressesForm } from '@/features/company-addresses/AddAddressDialog.tsx';

type PartAddAddressesForm = Pick<
  AddAddressesForm,
  | 'address1'
  | 'state'
  | 'city'
  | 'cityReplacement'
  | 'postalCode'
  | 'googlePlaceId'
  | 'latitude'
  | 'longitude'
>;
const formKeys: Required<keyof PartAddAddressesForm>[] = [
  'address1',
  'state',
  'city',
  'cityReplacement',
  'postalCode',
  'googlePlaceId',
  'latitude',
  'longitude',
];
const formToAddrInfoMap: Record<keyof PartAddAddressesForm, keyof AddressInfo> =
  {
    address1: 'streetAddress',
    state: 'state',
    city: 'city',
    cityReplacement: 'cityReplacement',
    postalCode: 'postalCode',
    googlePlaceId: 'googlePlaceId',
    latitude: 'latitude',
    longitude: 'longitude',
  };
export const AddressLookupFormField = () => {
  const { setFieldValue, submitCount } = useFormikContext<AddAddressesForm>();
  const [error, setError] = useState<TKeys<'companies'> | undefined>(
    'FIELD_IS_REQUIRED'
  );
  const { t } = useTranslate('companies');
  const handleChange = (address: AddressInfo | null) => {
    if (!address) {
      setError('FIELD_IS_REQUIRED');
      for (const key of formKeys) {
        void setFieldValue(key, '');
      }
    } else if (!address.isValid) {
      setError('PLEASE_CLARIFY_THE_ADDRESS' as TKeys<'companies'>);
    } else {
      setError(undefined);
      for (const key of formKeys) {
        void setFieldValue(key, address[formToAddrInfoMap[key]]);
      }
    }
  };
  const err = submitCount && error ? error : undefined;
  return (
    <AddressLookup
      onChange={handleChange}
      error={t(err)}
      placeholder={t('ENTER_YOUR_COMPANY_ADDRESS')}
    />
  );
};
