/** @jsxImportSource @emotion/react */
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionDetailsProps,
  AccordionSummary as MuiAccordionSummary,
  css as _,
  styled,
  SxProps,
} from '@mui/material';
import IconSVGExpandArrowDown from 'ui-kit/lib/icons/24/Arrow/ChevronDown';

import { alpha } from '@/theme/utils';

import { shouldNotForwardProps } from '@/utils/shouldNotForwardProps';
import { FC } from 'react';
import { IconButton } from '@/components/IconButton';

export const Accordion = styled(
  MuiAccordion,
  shouldNotForwardProps(['error'])
)<{ error?: boolean }>(
  ({ theme, error }) => _`
  &.MuiAccordion-root:before {
    background-color: ${
      error ? alpha(theme.palette.primary.main, 14) : theme.colors.borderLight
    };
    display: block !important;
  }
  &.Mui-expanded:before {
    opacity: 1;
  }
  &:first-of-type {
    border-top: 1px solid ${theme.colors.borderLight};
  }
  &:last-of-type {
    border-bottom: 1px solid ${theme.colors.borderLight};
  }
  .MuiAccordionSummary-root {
    background-color: ${error ? alpha(theme.palette.primary.main, 7) : ''};
  }
`
);
Accordion.defaultProps = {
  square: true,
  disableGutters: true,
  elevation: 0,
};

export const AccordionSummary = styled(MuiAccordionSummary)(
  () => _`
  flex-direction: row-reverse;
  gap: 12px;
  padding: 0 24px;
  height: 56px;
  .MuiAccordionSummary-content {
    min-width: 0;
  }
`
);
AccordionSummary.defaultProps = {
  expandIcon: (
    <IconButton color={'tertiary'} size={'lg'}>
      <IconSVGExpandArrowDown />
    </IconButton>
  ),
};

export const AccordionDetails: FC<AccordionDetailsProps> = ({
  sx,
  ...restProps
}) => {
  const styles: SxProps = {
    padding: 24,
    paddingLeft: {
      xs: `${24 + 12}px`,
      sm: `${24 + 10 + 10 + 25}px`,
    },
  };
  return (
    <MuiAccordionDetails
      {...restProps}
      sx={[styles, ...(Array.isArray(sx) ? sx : [sx])]}
    />
  );
};
