import { apiClient } from '@/api/client/ApiClient';
import { User, UUID, Page, ModuleUUID } from '@/types';
import { Role } from '@/api/__generated__/webApi';

export const getRoles = async ({
  companyId,
  moduleId,
}: {
  companyId: UUID;
  moduleId: UUID;
}) => {
  const response = await apiClient.get<Role[]>(
    `/api/companies/${companyId}/company-modules/${moduleId}/roles`,
    { params: { permissionsCompanyId: companyId } }
  );
  return response.data;
};

interface GetUsersParams {
  moduleId: ModuleUUID;
  companyId: UUID;
  roleId: UUID;
  filter: string;
  startPage: number;
  perPage: number;
}
export const getUsersByPage = async ({
  moduleId,
  roleId,
  companyId,
  ...params
}: GetUsersParams) => {
  const response = await apiClient.get<Page<User>>(
    `/api/companies/${companyId}/company-modules/${moduleId}/roles/${roleId}/users`,
    { params: { ...params, permissionsCompanyId: companyId } }
  );
  return response.data;
};

export const removeUser = async ({
  companyId,
  moduleId,
  roleId,
  users,
}: {
  companyId: UUID;
  moduleId: UUID;
  roleId: UUID;
  users: UUID[];
}) => {
  await apiClient.post(
    `/api/companies/${companyId}/company-modules/${moduleId}/roles/${roleId}/users/fn/remove`,
    { ids: users },
    { params: { permissionsCompanyId: companyId } }
  );
};
export const addUser = async ({
  companyId,
  moduleId,
  roleId,
  users,
}: {
  companyId: UUID;
  moduleId: UUID;
  roleId: UUID;
  users: UUID[];
}) => {
  await apiClient.post(
    `/api/companies/${companyId}/company-modules/${moduleId}/roles/${roleId}/users/fn/add`,
    { ids: users },
    { params: { permissionsCompanyId: companyId } }
  );
};

export * as companyModulesApi from './';
