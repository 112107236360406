/** @jsxImportSource @emotion/react */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormikRedux,
  Grid,
} from '@/components';
import { phoneNotRequiredValidator } from '@/features/authentication/common/validators';
import { useTranslate } from '@/i18n/useTranslate';
import { FC, ReactNode, useMemo } from 'react';
import * as yup from 'yup';
import { useCreateAddressForm } from '@/store/companies/hooks/useCreateAddressForm';
import { SwitchControlF } from '@/components/SwitchControl';
import { InvoiceReceivingMessage } from './EditAddressDialog';
import { CompanyAddress, StateType } from '@/api/__generated__/webApi';
import { Stack, SxProps, Theme } from '@mui/material';
import { BillTypesControls } from '@/features/company-addresses/BillTypesControls';
import { PhoneInputControlF } from '@/components/FormikRedux/PhoneInputControlF';
import { spreadSx } from '@/utils/spreadSx';
import { InputControlF } from '@/components/FormikRedux/InputControlF';
import { useBreakpoints } from 'ui-kit';

import { EMPTY } from '@/constants';
import { AddressLookupFormField } from '@/features/company-addresses/AddressLookupFormField.tsx';

export type AddAddressesForm = Omit<
  CompanyAddress,
  'endDate' | 'id' | 'state'
> & { state: StateType | null };
const initialValues: AddAddressesForm = {
  address1: '',
  address2: '',
  state: null,
  city: '',
  cityReplacement: '',
  postalCode: '',
  telephoneNumber: '',
  faxNumber: '',
  googlePlaceId: '',
  invoicesReceiving: false,
  insuranceBillType: false,
  patientBillType: false,
  clientBillType: false,
};

export const GrayLayout: FC<{ children: ReactNode; sx?: SxProps<Theme> }> = ({
  children,
  sx,
}) => {
  return (
    <Stack
      sx={[
        { backgroundColor: 'grey.200', padding: { xs: '24px 18px' } },
        ...spreadSx(sx),
      ]}
    >
      {children}
    </Stack>
  );
};
const useValidationSchema = () => {
  const { t } = useTranslate('auth');
  return useMemo(() => {
    return yup.object().shape({
      address1: yup.string().required(t('FIELD_IS_REQUIRED')),
      address2: yup.string(),
      state: yup.string().nullable().required(t('FIELD_IS_REQUIRED')),
      city: yup.string().required(t('FIELD_IS_REQUIRED')),
      postalCode: yup.string().required(t('FIELD_IS_REQUIRED')),
      telephoneNumber: phoneNotRequiredValidator(t),
      faxNumber: phoneNotRequiredValidator(t),
    });
  }, [t]);
};
export const AddAddressDialog = () => {
  const { t } = useTranslate('companies');
  const validationSchema = useValidationSchema();
  const { dialogOpened, closeDialog, errors, resetErrors, submit } =
    useCreateAddressForm();
  const b = useBreakpoints();
  return (
    <Dialog
      open={!!dialogOpened}
      size={b.smOnly ? 'xl' : 'xxl'}
      onClose={closeDialog}
    >
      <DialogTitle onClose={closeDialog}>{t('ADD_ADDRESS')}</DialogTitle>
      <DialogContent sx={{ paddingY: { xs: 18 } }}>
        <FormikRedux<AddAddressesForm>
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={submit}
          serverErrors={errors ?? {}}
          resetServerErrors={resetErrors}
          id={'add_address_form'}
        >
          {({ submitCount }) => {
            return (
              <Stack gap={12}>
                <GrayLayout sx={{ gap: 24 }}>
                  <AddressLookupFormField />
                  <InputControlF
                    name={'address2'}
                    max={55}
                    placeholder={t('ENTER_APARTMENT_SUITE_OR_UNIT')}
                  />
                </GrayLayout>
                <GrayLayout
                  sx={{ paddingTop: { xs: 36 }, paddingBottom: { xs: 24 } }}
                >
                  <Grid
                    container
                    columnSpacing={{ xs: 24, sm: 42 }}
                    rowSpacing={{
                      xs: 42,
                      sm: 28,
                    }}
                  >
                    <Grid xs={12} sm={6} md={6}>
                      <PhoneInputControlF
                        ignoreLabelHeight
                        name={'telephoneNumber'}
                        label={t('PHONE_NUMBER')}
                        placeholder={EMPTY}
                        submitCount={submitCount}
                        optional
                      />
                    </Grid>
                    <Grid xs={12} sm={6} md={6}>
                      <PhoneInputControlF
                        ignoreLabelHeight
                        submitCount={submitCount}
                        name={'faxNumber'}
                        label={t('FAX_NUMBER')}
                        optional
                      />
                    </Grid>
                  </Grid>
                </GrayLayout>
                <GrayLayout sx={{ paddingY: { xs: 18 } }}>
                  <BillTypesControls />
                  <SwitchControlF
                    label={t('INVOICES_RECEIVING')}
                    sx={{
                      height: { xs: 28 },
                      mb: { xs: 12 },
                      mt: { xs: 24 },
                      justifyContent: 'start',
                      gap: 16,
                    }}
                    name={'invoicesReceiving'}
                  />
                  <InvoiceReceivingMessage />
                </GrayLayout>
              </Stack>
            );
          }}
        </FormikRedux>
      </DialogContent>
      <DialogActions spaceBetween>
        <Button variant="outlined" color={'secondary'} onClick={closeDialog}>
          {t('CANCEL')}
        </Button>
        <Button type={'submit'} form={'add_address_form'} color="secondary">
          {t('SAVE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
