import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { boxXPaddings, boxYPaddings, Warning } from '@/components';
import { Stack, Theme, Typography } from '@mui/material';
import { useTranslate } from '@/i18n/useTranslate';
import { Button, useBoxBreakpoints } from 'ui-kit';
import {
  TreeExternalHandle,
  TreeFragment,
} from '@/features/module/LIMS/fragments/TreeFragment';
import { SelectedPermissionsFragment } from '@/features/module/LIMS/fragments/SelectedPermissionsFragment';
import { CONTENT_BOX_ID } from '@/features/layouts/MainLayout/MainLayout';
import { AppRoutes, generatePath, useParams } from '@/routes/appRoutes.ts';
import { usePermissionsSelector } from '@/features/module/LIMS/hooks/usePermissionsSelector.ts';
import {
  BreadcrumbsFragment,
  BreadcrumbsNew as Breadcrumbs,
} from '@/components/Breadcrumbs';
import { useRoles } from '@/store/companies/hooks';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@/store/store';
import { savePermissions } from '@/store/module/api';
import { companiesActions as actions } from '@/store/companies/slice.ts';
import { WhiteBoxLayout } from '@/features/module/LIMS/WhiteBoxLayout.tsx';
import { usePageInit } from '@/features/module/LIMS/hooks/usePageInit.ts';
import { NEW_ROLE_ID_STUB } from '@/features/module/constants.ts';
import { showErrorToast } from '@/components/Toast/showErrorToast.tsx';

const hasChanges = (arr1: string[] = [], arr2: string[] = []) => {
  const a = new Set(arr1);
  const b = new Set(arr2);
  const diffA = a.difference(b);
  const diffB = b.difference(a);
  return !!diffA.size || !!diffB.size;
};

export const LimsPermissionsPage: FC<{ editable?: boolean }> = ({
  editable,
}) => {
  const params = useParams();
  const { companyId, roleId, moduleId, module } = params;
  const dispatch = useAppDispatch();
  const containerBreakpoints = useBoxBreakpoints(CONTENT_BOX_ID, ['md']);
  const navigate = useNavigate();
  const { t } = useTranslate('module');

  const treeExternalHandleRef = useRef<TreeExternalHandle>(null);
  const [saving, setSaving] = useState(false);
  const [cancelRequestExists, setCancelRequest] = useState(false);

  const { initLoading, selected, preSelected } = usePermissionsSelector(params);
  const { role } = useRoles();
  usePageInit();

  const linkBack = useMemo(
    () => ({
      pathname: generatePath(AppRoutes.COMPANY_MODULES, {
        companyId,
        moduleId,
        module,
      }),
      search: window.location.search,
    }),
    [companyId, module, moduleId]
  );

  useEffect(() => {
    if (roleId === NEW_ROLE_ID_STUB && !role) {
      navigate(linkBack);
    }
  }, [linkBack, navigate, role, roleId]);

  const handleCancel = () => {
    const hasNoChanges = !hasChanges(selected, preSelected);
    if (hasNoChanges || cancelRequestExists) {
      navigate(linkBack);
    } else {
      setCancelRequest(true);
    }
  };

  const handleSave = async () => {
    if (!roleId || !companyId || !moduleId) {
      console.error('param missing', params);
      return;
    }

    if (roleId === NEW_ROLE_ID_STUB) {
      const selectedSet = new Set(selected);
      const preSelectedSet = new Set(preSelected);
      const toAdd = selectedSet.difference(preSelectedSet); // new ones
      if (!toAdd.size) {
        showErrorToast({ text: t('UNABLE_TO_CREATE_ROLE_WITHOUT_ANY_') });
        return;
      }
    }

    setSaving(true);
    await dispatch(savePermissions({ roleId, moduleId, companyId }));
    dispatch(actions.requestRoles({ companyId, moduleId, replace: true }));
    setSaving(false);
    navigate(linkBack);
  };

  const buttons = (
    <>
      <Button
        variant={'outlined'}
        color={'secondary'}
        onClick={handleCancel}
        disabled={saving}
      >
        {t('CANCEL')}
      </Button>
      <Button color={'secondary'} onClick={handleSave} disabled={saving}>
        {t('SAVE')}
      </Button>
    </>
  );

  const leftColumnContent = (
    <>
      <TreeFragment
        ref={treeExternalHandleRef}
        editable={Boolean(editable) && !saving}
      />
      {!containerBreakpoints.md && editable && !initLoading && (
        <Stack sx={styles.footer}>{buttons}</Stack>
      )}
    </>
  );

  const rightColumnContent = (
    <SelectedPermissionsFragment
      showNode={treeExternalHandleRef.current?.expand}
      readOnly={!editable}
      buttons={buttons}
    />
  );

  return (
    <>
      <Stack gap={{ xs: 12, sm: 24 }}>
        <Breadcrumbs>
          <BreadcrumbsFragment direction={'left'} onClick={handleCancel}>
            {t('BACK_TO_LABORATORY_ROLES')}
          </BreadcrumbsFragment>
        </Breadcrumbs>
        <Typography component={'h2'} sx={styles.header}>
          {t('ROLE_PERMISSIONS', { name: role?.name })}
        </Typography>
      </Stack>
      <WhiteBoxLayout
        leftColumnContent={leftColumnContent}
        rightColumnContent={rightColumnContent}
      />
      <Warning
        open={cancelRequestExists}
        title={t('LEAVE_THIS_PAGE')}
        onClose={() => setCancelRequest(false)}
        buttons={[
          {
            children: t('CANCEL'),
            variant: 'secondary',
            onClick: () => setCancelRequest(false),
          },
          { children: t('LEAVE'), variant: 'primary', onClick: handleCancel },
        ]}
      >
        {t('YOUR_CHANGES_WILL_NOT_BE_SAVED')}
      </Warning>
    </>
  );
};

const styles = {
  footer: {
    flexDirection: { xs: 'column-reverse', sm: 'row' },
    paddingX: boxXPaddings,
    paddingY: { xs: 18, sm: 24 },
    justifyContent: 'end',
    gap: { xs: 12, lg: 12 },
    borderTop: (t: Theme) => `1px solid ${t.palette.secondary[300] ?? ''}`,
  },
  headerRow: {
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: { xs: 18 },
  },
  leftTopSection: {
    paddingX: boxXPaddings,
    paddingTop: boxYPaddings,
    paddingBottom: { xs: 18 },
    borderBottom: (t: Theme) => `1px solid ${t.palette.secondary[300] ?? ''}`,
  },
  header: {
    fontSize: { xs: '20px', sm: '24px', lg: '32px' },
    fontWeight: 700,
    lineHeight: { xs: '24px', sm: '36px', lg: '42px' },
  },
  expandPath: {
    gap: 6,
    paddingX: { xs: 12, sm: 24 },
    paddingY: 18,
  },
  breadcrumbs: {
    gap: 4,
    alignItems: 'center',
  },
};
